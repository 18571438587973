import React from "react";
import { Link } from "react-router-dom";
import "./ArticleOne.scss";
import NewsPhotoThree from "../../../assets/images/Photos/EveryChildMatters.png";

import Footer from "../../../components/Footer/Footer";

const ArticleOne = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">Sept 29, 2023</span>
        <h3 className="article-page__title">
          Observing the National Day for Truth and Reconciliation
        </h3>
        <p className="article-page__text">
          Tomorrow we at RSG International join the rest of Canada in observing
          the National Day for Truth and Reconciliation.
          <br></br>
          <br></br>
          As global leaders in road safety infrastructure, we are committed to
          creating solutions that make the roadway safer for everyone, including
          Indigenous peoples. We recognize the tragic legacy of residential
          schools and the ongoing impacts of colonization on First Nations,
          Inuit, and Métis peoples. Also acknowledging that our work takes place
          on the traditional territories of many Indigenous nations across North
          America.
          <br></br>
          <br></br>
          On this day, we encourage our employees to wear orange to show
          solidarity with survivors and their descendants. We also invite you to
          explore the rich and diverse cultures, voices, experiences, and
          histories of Indigenous peoples through the resources available online
          and provided by the Government of Canada. Together, we can make a
          difference in advancing reconciliation and healing.
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoThree}
          alt="Logo for EveryChildMatters. A painting of hands in a circle, with the words 'Every Child Matters' in the middle."
        />
        <div className="first-navigation">
          <Link
            to="/moving-road-safety-forward-through-innovation-with-elmo"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleOne;
