import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwentySeven.scss";
import NewsPhotoTwentySeven from "../../../assets/images/Photos/NewsPhotoTwentySeven.png";

import Footer from "../../../components/Footer/Footer";

const ArticleTwentySeven = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">September 16, 2024</span>
        <h3 className="article-page__title">
          RSG International Introduces Inclusive PPE in Partnership with Mark’s
          Commercial
        </h3>
        <p className="article-page__text">
          RSG International is proud to lead the construction industry in
          innovation by launching inclusive personal protective equipment (PPE)
          through our new partnership with Mark’s Commercial. Our mission is to
          provide safety gear that meets the diverse needs of all our workers,
          regardless of gender, religious beliefs, or background.
          <br></br>
          <br></br>
          “We’re committed to providing PPE that goes beyond simply fitting men
          and women—we aim to offer safety gear that is inclusive for everyone,
          regardless of gender, religious beliefs, or background. The safety
          equipment that protects our workers should be accessible to all,” says
          Lisa Laronde, President of RSG International. “By focusing on
          inclusivity, we ensure that every team member feels valued and
          protected. Safety shouldn’t be one-size-fits-all; it should reflect
          the diversity of the workforce, allowing everyone to perform their
          best while staying safe on the job.”
          <br></br>
          <br></br>
          Recognizing that traditional PPE often falls short in accommodating
          women and non- binary individuals, we’ve taken a proactive approach.
          Our recent trial of women’s sizes in the Health and Safety department
          at Powell Contracting has yielded outstanding feedback, highlighting
          the importance of well-fitting safety gear.
          <br></br>
          <br></br>
          "We take great pride in partnering with forward-thinking companies
          like RSG International and industry leaders such as Lisa Laronde, who
          prioritize employee safety and well-being," said Adam Gaiser, Vice
          President of Mark's Commercial. "Over the past year, we've been
          thrilled to work with Lisa and her team to develop innovative,
          inclusive PPE products that fit every member of RSG's diverse
          workforce. By collaborating, Mark's Commercial and RSG International
          are not only introducing new technologies like VizLite® High
          Visibility PPE, but we are also making our product line truly
          inclusive—designing garments that accommodate all employees, including
          those specifically tailored for women.”
          <br></br>
          <br></br>
          At RSG International, we believe that safety shouldn’t be a
          one-size-fits-all solution. By embracing inclusivity in our PPE
          offerings, we are setting a new standard for worker safety and
          well-being in the construction industry. We are excited to continue
          testing innovative solutions, such as VizLite® tape, to further
          enhance our safety measures in work zones.
          <br></br>
          <br></br>
          Join us as we pave the way for a more inclusive and safer workplace for everyone.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTwentySeven}
          alt="People wearing PPE on a job site."
        />

        <div className="last-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/national-day-for-truth-and-reconciliation-2024" // Update the link to the previous article
            className="nav-button"
          >
            Previous
          </Link>
          {/* Add next button if there is a following article */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwentySeven;
