import React from "react";
import { Link } from "react-router-dom";
import "./ArticleFourteen.scss";
import NewsPhotoFourteen from "../../../assets/images/Photos/NewsPhotoFourteen.jpg";

import Footer from "../../../components/Footer/Footer";

const ArticleFourteen = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">May 08, 2024</span>
        <h3 className="article-page__title">
          Chantal Séguin Elected President of the Simcoe County Heavy
          Construction Association
        </h3>
        <p className="article-page__text">
          <br></br>
          The Simcoe County Heavy Construction Association (SCHCA) has named
          Chantal Séguin its new and first woman president, marking a
          significant milestone in the organization’s history and the
          construction industry.
          <br></br>
          <br></br>
          Séguin serves as the General Manager of Summit Rentals, a prominent
          supplier of road safety devices specializing in temporary concrete
          barriers, noise barriers, and crash cushions. Summit Rentals is a
          division of RSG International, a global leader in the road safety
          infrastructure sector.
          <br></br>
          <br></br>
          “I am honoured to have been appointed as President, the members have
          advocated for me and championed my causes since joining the board in
          2019,” explained Séguin. “I love that we advocate for members within
          Simcoe County, a place I happily call home.”
          <br></br>
          <br></br>
          Bringing over a decade of experience in heavy construction, Séguin has
          held management positions for general contracting road construction,
          rail, and road safety devices. She is also a member of the Board of
          Directors for the Barrie Construction Association (BCA).
          <br></br>
          <br></br>
          “RSG International is thrilled for Chantal (Séguin) as she takes on
          this new role. She is laying the foundation for a future where
          equality and opportunity know no bounds. A tireless advocate, we’re
          excited to witness her transformative impact on the industry,” added
          Lisa Laronde, the first woman President of RSG International.
          <br></br>
          <br></br>
          Séguin says during her two-year term she intends to keep advocating
          for youth through various grassroots programs offered by the
          association while exposing guidance counselors to the trades.
          <br></br>
          <br></br>
          “They’re the driving force behind the industry and we need them to
          carry our message, that the trades lack profitability because it is
          quite the opposite, these are skilled jobs and we need new workers to
          sustain the industry,” remarked Séguin.
          <br></br>
          <br></br>
          Séguin was recently announced as the new president at the Engineer’s
          Night.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoFourteen}
          alt="Chantal Séguin posing for a photo with arms crossed in front of a white background."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/recognizing-mental-health-awareness-month"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/rsg-international-hr-team-clinches-the-innovative-hr-teams-award"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleFourteen;
