import React from "react";
import "./AboutUs.scss";

const AboutUs = () => {
    return (
        <div className="aboutus">
            <div className="aboutus__container">
                <h1 className="aboutus__title">About Us</h1>
            </div>
        </div>
    );
}

export default AboutUs;
