import React, { useState } from "react";
import emailjs from "emailjs-com";
import { CSSTransition } from 'react-transition-group';
import "./ContactForm.scss";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .send(
        "service_mflh9t4",
        "template_yg5xoxw",
        formData,
        "F0UD6u-dwWyAE2MYG"
      )
      .then(
        (response) => {
          console.log(
            "Email sent successfully!",
            response.status,
            response.text
          );
          setIsSubmitted(true);
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
        },
        (error) => {
          console.log("Error sending email:", error);
        }
      );
  };

  return (
    <div className="contact-form-container">
      <div className="form-content">
        <h2 className="contact-form__title">Send Us a Message</h2>
        
        <CSSTransition
          in={isSubmitted}
          timeout={500}
          classNames="fade"
          unmountOnExit
        >
          <p>Thank you for reaching out! We'll get back to you shortly.</p>
        </CSSTransition>
        
        {!isSubmitted && (
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="contact-form__group">
              <input
                className="contact-form__input"
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                className="contact-form__input"
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <input
              className="contact-form__input"
              type="text"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
            />
            <textarea
              className="contact-form__textarea"
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <button className="contact-form__button" type="submit">
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
