import React from "react";
import "./ContactPage.scss";

import ContactHeader from "./ContactHeader/ContactHeader";
import ContactForm from "./ContactForm/ContactForm";
import GetInTouch from "./GetInTouch/GetInTouch";
import Footer from "../../components/Footer/Footer";

const Contactpage = () => {
  return (
    <>
      <main>
        <ContactHeader />
        <ContactForm />
        <GetInTouch />
        <Footer />
      </main>
    </>
  );
};

export default Contactpage;