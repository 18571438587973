import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTen.scss";
import NewsPhotoTen from "../../../assets/images/Photos/NewsPhotoTen.jpeg";

import Footer from "../../../components/Footer/Footer";

const ArticleTen = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">March 08, 2024</span>
        <h3 className="article-page__title">
          Lisa Laronde Launches Podcast on International Women’s Day
        </h3>
        <p className="article-page__text">
          <br></br>
          Lisa Laronde, recognized as one of Canada’s ‘Top 100 Most Powerful
          Women’, is a dynamic advocate for women in construction and leadership
          roles. Being the first woman President of RSG International, Lisa has
          not only shattered stereotypes but also led the company to experience
          a significant surge in profit under her leadership, setting a
          precedent for others in the male-dominated industry. Now, the highly
          sought-after keynote speaker is embarking on an exciting journey as
          she launches a podcast on a new and exciting women’s network aimed at
          empowering women to lead with confidence and drive positive change.
          <br></br>
          <br></br>
          “The Powerhouse Project” recently launched on the{" "}
          <a
            href="https://www.womeninmedia.network/"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            Women In Media Network
          </a>
          , (WIMN) a new home for women and gender-diverse podcasters on a
          mission to uplift, inspire, and empower one another.
          <br></br>
          <br></br>
          “Joining the Women in Media Network is an opportunity to reach and
          inspire women from around the globe. It is a place to celebrate our
          collective success and provides an opportunity to come together, lift
          one another, and foster success,” adds Lisa Laronde, show host and the
          first woman President of RSG International, a global leader in road
          safety infrastructure.
          <br></br>
          <br></br>
          Created by Canadian media personality, producer, and host of the
          popular Women in Media podcast, Sarah Burke. Burke’s vision for WIMN
          is more than just an advocacy group; it’s a movement.
          <br></br>
          <br></br>
          “It's about transforming the media landscape into a diverse and
          equitable arena where women's voices are not just heard but are
          influential,” explains Burke. “The media landscape is in a perpetual
          state of turbulence right now, and I’m excited about empowering
          creators with newfound freedom from the organizations they were once
          tied to, and the power to make decisions regarding their content.”
          <br></br>
          <br></br>
          Laronde joins a multi-platinum, award-winning singer, songwriter,
          actor, author, and podcaster Jann Arden, in addition to TV host, home
          designer, and author Debbie Travis. The network highlights a wide
          range of other women creators ranging from relationships and health to
          finance and women's issues in addition to careers and real estate.
          <br></br>
          <br></br>
          “I know what it takes to succeed in this exciting and ferocious world.
          The platform Sarah (Burke) has created shines a light on women who
          inspire, women who are struggling, women who are role models, and
          women who are unafraid to speak their minds,” explains Debbie Travis,
          the British-Canadian television personality.
          <br></br>
          <br></br>
          ‘The Powerhouse Project’ is a bi-weekly podcast sponsored by RSG
          International to recognize women in construction and leadership roles.
          It covers leadership from a variety of angles inviting distinguished
          guests to speak about their experiences in tech, politics,
          construction, and more. Lisa operates with a no-filter policy and
          no-nonsense approach that surprises some but inspires many.
          <br></br>
          <br></br>
          “We hope listeners connect with Lisa’s electric personality, she is
          the type of person who ignites a spark from within, infusing those
          around her with a sense of drive and ambition. The podcast is thought-
          provoking and aims to inspire our listeners to embrace their potential
          while exploring the failures and success stories of others,” adds
          Morganne Campbell, Public Relations Manager at RSG International.
          <br></br>
          <br></br>
          Lisa is also the President of the Canadian Association of Women in
          Construction (CAWIC) and holds a seat on the largely male-dominated
          board of directors for the Ontario Road Builders’ Association (ORBA).
          <br></br>
          <br></br>
          New episodes are published every other Wednesday, you can join the
          movement at{" "}
          <a
            href="https://www.thepowerhouse-project.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            www.thepowerhouse-project.com
          </a>{" "}
          or follow us on Facebook and Instagram at ‘The Powerhouse Project
          Podcast.’
          <br></br>
          <br></br>
          Please read our press release{" "}
          <a
            href="https://www.thepowerhouse-project.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            here
          </a>
          .<br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTen}
          alt="Lisa Laronde standing in fron of an image with the podcast title."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/celebrating-women-in-construction-week"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/canadas-first-teledriven-crash-truck-debuted-at-intertraffic"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTen;
