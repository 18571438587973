import React from "react";
import "./MediaBanner.scss";

const MediaBanner = () => {
  return (
    <div className="mediabanner" data-aos="zoom-in-down" data-aos-duration="1000">
      <div className="mediabanner__container">
        <h1 className="mediabanner__title">Media</h1>
      </div>
    </div>
  );
};

export default MediaBanner;
