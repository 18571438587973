import React from "react";
import { Link } from "react-router-dom";
import "./ArticleSix.scss";
import NewsPhotoSix from "../../../assets/images/Photos/NewsPhotoSix.png";

import Footer from "../../../components/Footer/Footer";

const ArticleFive = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">Jan 22, 2024</span>
        <h3 className="article-page__title">
          Mark Ayton Receives TRB Lifetime Award
        </h3>
        <p className="article-page__text">
          <br></br>
          “Find a job you enjoy doing, and you will never have to work a day in
          your life,” those words spoken by Mark Twain ring true for Mark Ayton,
          a civil engineer at Safe Roads Research & Development, who has
          dedicated four decades to designing and building safer roads.
          <br></br>
          <br></br>
          “The day that it starts to become a drag or a chore, that’s the time
          to step away or change the channel, if you will,” explains Mark Ayton.
          <br></br>
          <br></br>
          Ayton was recently recognized for his dedication to the industry by
          the Transportation Research Board (TRB) and was awarded the Kenneth A.
          Stonex Roadside Safety Award, which recognizes lifetime contributions
          to roadside safety. Established in 1991, the award was originally
          sponsored by General Motors, and named after an employee who was a
          pioneer for roadside safety long before the seriousness of ran off
          road crashes was recognized by transportation agencies.
          <br></br>
          <br></br>
          “It was a shock when they called me to say that I was receiving the
          award, look at the list of people who have won that award. There are
          some heavy hitters on that list,” adds Ayton. “To be considered in the
          same breath as them is pretty overwhelming.”
          <br></br>
          <br></br>
          Looking back at his career, Ayton has a long list of accomplishments,
          and rightfully so. He spent more than 30 years with the Ministry of
          Transportation, with the first 12 years at the MTO’s London office
          where he worked in design and construction. Ayton moved to the St.
          Catharines office where he spent his last 19 years working in
          engineering standards where became responsible for the MTO’s Roadside
          Design Manual, Geometric Design Manual, and all the related design
          policies, guidelines, and construction standards that went along with
          it.
          <br></br>
          <br></br>
          “Now that I look back over the years, we probably have saved a lot of
          lives. I guess it's something we just never take account of especially
          median barrier projects,” adds Ayton.
          <br></br>
          <br></br>
          His most noteworthy accomplishments include the design of highway 401
          concrete median barrier and widening between London and Woodstock in
          the 1990’s, which was a large undertaking for a young engineer at the
          time, Ayton still feels a sense of pride when he travels that stretch
          of highway.
          <br></br>
          <br></br>
          Another notable project was the 120-kilometer stretch of the 401 just
          west of London, dubbed ‘carnage alley’ which made headlines when
          safety advocates began lobbying the provincial government for the
          widening of the highway from 4 lanes to 6 including concrete median
          barriers. That project would take years to get off the ground and
          Ayton knew that, and instead introduced a new plan, installing a high
          tension four cable median barrier system, making the highway safer
          within a shorter timeframe.
          <br></br>
          <br></br>
          “I found an article from three years ago that pointed at the annual
          cost of repairs to the cable barrier system, it highlighted repairs
          from 68 different impacts. What the article failed to mention was how
          each of those impacts potentially prevented a vehicle from crossing
          the median into opposing traffic, leading to a potential fatality or
          serious injury. These are the success stories we need to get out
          there.”
          <br></br>
          <br></br>
          Now Ayton spends his days with his team at Safe Roads Research &
          Development, a division of RSG International. He is a technical expert
          in geometric and road safety design, developing, and testing
          cutting-edge road safety products.
          <br></br>
          <br></br>
          “I met Mark around 2000 and his passion for roadside safety was
          immediately evident. Ontario and all of Canada have benefitted from
          his passion. Fatalities from roadside accidents have continuously
          diminished under his watch at MTO. Working with him since 2018 has
          been a capstone to my career,” adds Dean Alberson, a Senior Research
          Engineer at Safe Roads Research & Development.
          <br></br>
          <br></br>
          Ayton jokes that since joining the team five years ago his job doesn’t
          seem like work as he develops and crash tests products including
          several that are patented including a combination MASH TL-3 and TL-4
          traffic barriers with soundwall. He says he enjoys partnering with
          outside organizations including the Ontario Provincial Police.
          <br></br>
          <br></br>
          “The R&D team is a team full of innovators and we're always looking at
          trying to come up with new products that'll do things better, more
          cost-effectively, and be easier to place out in the road,” explains
          Ayton. “You can probably put our team up against anybody, I don’t
          think there’s a better team out there, we’re disrupters, we’re looking
          at changing how things are done.”
          <br></br>
          <br></br>
          These words were echoed by Lisa Laronde, president of RSG
          International.
          <br></br>
          <br></br>
          “Our success as an industry hinges on people like Mark Ayton, a
          brilliant mind whose goal is to create safe roads for motorists and
          prevent fatalities. We're proud of Mark’s accomplishments and are
          confident in his ability to rise to the occasion,” adds Laronde.
          <br></br>
          <br></br>
          But 16 months ago, life as Ayton knew it changed, he was getting ready
          to compete in a half-ironman distance triathlon when his wife Joanne
          noticed his skin was turning yellow. After some convincing Ayton found
          himself in a local emergency department and following a series of
          medical tests over the course of a week and was diagnosed with stage
          four pancreatic cancer.
          <br></br>
          <br></br>
          “Work keeps me going, Lisa, Ben, Bill, they’ve all been so
          accommodating,” explains Ayton who also says another motivator is
          participating in endurance sports.
          <br></br>
          <br></br>
          In fact, in September of 2023 Ayton has his shot a do over and
          participated in the triathlon he missed the year before. Ayton is only
          person with stage four cancer, also on chemotherapy, to ever complete
          the Barrelman Triathlon in Niagara Falls.
          <br></br>
          <br></br>
          “I defied expectations by not only surviving year one of my cancer
          journey, but I completed a grueling and demanding endurance race
          placing 469th out of 600.”
          <br></br>
          <br></br>
          And the father of three says he plans to continue to defy the odds, he
          intends to be part of the 12% of people who survive pancreatic cancer
          for more than five years. This means he will continue his work with
          Safe Roads, admittedly it doesn’t feel like work as it is a job he
          loves to do, he has this piece of advice for up-and-comers.
          <br></br>
          <br></br>
          “Work hard and look around, you never know what you can learn on a
          site just by being interested and open to learning new skills. It can
          open doors and introduce new opportunities you never knew existed.”
          <br></br>
          <br></br>
          Ayton joins a long list of award recipients which can be found{" "}
          <a
            href="https://sites.google.com/site/trbcommitteeakd20/awards?authuser=0"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            here
          </a>
          .<br></br>
          <br></br>
          Please read our press release{" "}
          <a
            href="https://www.einnews.com/pr_news/681919794/canadian-civil-engineer-recognized-for-contributions-to-roadside-safety-at-us-based-awards-ceremony"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            here
          </a>
          .<br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoSix}
          alt="The CEO of RSG International, Lisa Laronde, standing in front of Powell Construction equipment."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/rsg-international-looks-to-team-up-employees-with-ai-partner"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/lisa-laronde-is-appointed-to-the-board-of-orba"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleFive;
