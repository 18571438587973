import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwentyFour.scss";
import NewsPhotoTwentyFour from "../../../assets/images/Photos/NewsPhotoTwentyFour.png";

import Footer from "../../../components/Footer/Footer";

const ArticleTwentyFour = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">August 21, 2024</span>
        <h3 className="article-page__title">
          How to Save on Construction Costs with Used Concrete Barriers
        </h3>
        <p className="article-page__text">
          Managing construction costs without compromising quality is key to any
          successful project. One effective way to achieve this is by
          incorporating{" "}
          <a href="https://strbk.com/services/used-concrete-barriers/">
            used concrete barrier
          </a>{" "}
          into your planning. These concrete barriers offer durable and
          versatile solutions at a fraction of the cost compared to new
          materials. Whether your project involves organizing a worksite,
          managing traffic, or improving site security, used highway barriers
          can meet your needs while keeping your budget in check.
          <br></br>
          <br></br>
          <h3 className="article-page__subtitle">
            Cost-Effective Solutions with Highway Barriers
          </h3>
          <br></br>
          When managing a construction project, controlling costs is a large
          part of the planning process. Opting for{" "}
          <a href="https://strbk.com/services/used-concrete-barriers/">
            used concrete jersey barriers
          </a>
          can be a budget-friendly solution. Used concrete barriers offer the
          same durability and strength as new ones but at a fraction of the
          cost. If you're looking to set up boundaries or organize equipment,
          purchasing from a reliable provider like B&K Enterprises ensures you
          have access to high-quality, affordable barriers across Pennsylvania
          and Maryland with their used concrete barriers for sale.
          <br></br>
          <br></br>
          <h3 className="article-page__subtitle">
            Versatility and Environmental Benefits of Used Jersey Barriers
          </h3>
          <br></br>
          In addition to costs savings, using{" "}
          <a href="https://strbk.com/services/temporary-and-permanent-barrier/">
            pre-owned jersey barriers
          </a>{" "}
          aligns with sustainable construction practices. Reusing materials
          reduces waste, and these concrete construction barriers are versatile
          for various applications. They are available for immediate use while
          helping you achieve sustainability.
          <br></br>
          <br></br>
          When you’re looking for affordable and sustainable options for your
          next construction project, we would recommend looking into used
          concrete barriers.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTwentyFour}
          alt="Road safety barriers."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/national-traffic-awareness-month"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/rsg-international-celebrates-national-construction-appreciation-week"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwentyFour;
