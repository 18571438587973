import React from "react";
import "./WhatWeDo.scss";
import WhatWeDoImage from "../../../assets/images/Photos/WhatWeDoLarge.png";
import CheckListIcon from "../../../assets/icons/ChecklistIcon.png";

const WhatWeDo = () => {
  return (
    <main className="whatwedo">
      <div className="whatwedo__content">
        <h2 className="whatwedo__title">What We Do</h2>
        <p className="whatwedo__text">
        We devise cutting-edge solutions for life saving problems. We’re dedicated to improving road safety through meticulous design and boundless creativity, fostering a welcoming culture that puts people, safety, and sustainability at the forefront.
          <br></br><br></br>
          As a global organization, we strive to push the boundaries of engineering and continually find better, more sustainable solutions to keep road users safe.
        </p>

        <div className="whatwedo__list">
          <div className="whatwedo__list-left">
            <div className="whatwedo__list-item">
              <img
                src={CheckListIcon}
                alt="Checklist Icon"
                className="whatwedo__list-item-icon"
              />
              <p className="whatwedo__list-item-text">
                Construction and Installation
              </p>
            </div>
            <div className="whatwedo__list-item">
              <img
                src={CheckListIcon}
                alt="Checklist Icon"
                className="whatwedo__list-item-icon"
              />
              <p className="whatwedo__list-item-text">
                Product Distribution
              </p>
            </div>
          </div>

          <div className="whatwedo__list-right">
            <div className="whatwedo__list-item">
              <img
                src={CheckListIcon}
                alt="Checklist Icon"
                className="whatwedo__list-item-icon"
              />
              <p className="whatwedo__list-item-text">
                New Product Development
              </p>
            </div>
            <div className="whatwedo__list-item">
              <img
                src={CheckListIcon}
                alt="Checklist Icon"
                className="whatwedo__list-item-icon"
              />
              <p className="whatwedo__list-item-text">
                Auxiliary Services
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="whatwedo__image">
        <img
          className="whatwedo__image-photo"
          src={WhatWeDoImage}
          alt="A large truck parked at a construction site"
        />
      </div>
    </main>
  );
};

export default WhatWeDo;
