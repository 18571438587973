import React, { Suspense } from "react";
import "./AccessibilityPage.scss";

const AccessibilityBanner = React.lazy(() =>
  import("./AccessibilityBanner/AccessibilityBanner")
);
const AccessibilityRequest = React.lazy(() =>
  import("./AccessibilityRequest/AccessibilityRequest")
);
const Footer = React.lazy(() => import("../../components/Footer/Footer"));

const Accessibilitypage = () => {
  return (
    <>
      <main>
        <Suspense fallback={<div>Loading accessibility components...</div>}>
          <AccessibilityBanner />
          <AccessibilityRequest />
          <Footer />
        </Suspense>
      </main>
    </>
  );
};

export default Accessibilitypage;
