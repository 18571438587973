import React, { useState } from 'react';
import './NavBar.scss';
import { Link } from 'react-router-dom';

import ColorLogo from "../../assets/images/Logos/RSG_Logo_Colour.png";
import WhiteLogo from '../../assets/images/Logos/MobileLogoWhite.png';
import MenuIcon from '../../assets/icons/MenuIcon.png';
import ExitIcon from '../../assets/icons/ExitIcon.png';

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={`navbar ${menuOpen ? 'menu-open' : ''}`}>
      <Link to="/" className="navbar__logo-link">
        <img src={menuOpen ? WhiteLogo : ColorLogo} alt="Logo" className="navbar__logo" />
      </Link>
      <img 
        src={menuOpen ? ExitIcon : MenuIcon} 
        alt="Menu Icon" 
        className="navbar__menu-icon" 
        onClick={() => setMenuOpen(!menuOpen)}
      />

      <div className={`navbar__links ${menuOpen ? 'visible' : ''}`}>
        <Link to="/about" className="navbar__link-item">About Us</Link>
        <Link to="/media" className="navbar__link-item">Media</Link>
        <Link to="/contact" className="navbar__link-item">Contact</Link>
        <Link to="https://rsginternational.applytojob.com/apply" className="navbar__link-item" target="_blank" rel="noopener noreferrer">Careers</Link>
      </div>
    </div>
  );
};

export default NavBar;
