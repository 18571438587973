import React, { Suspense } from "react";
import "./MediaPage.scss";

import MediaBanner from "./MediaBanner/MediaBanner";
import News from "./News/News"
const LatestTweets = React.lazy(() => import("./LatestTweets/LatestTweets"));
const Footer = React.lazy(() => import("../../components/Footer/Footer"));

const Mediapage = () => {
  return (
    <>
      <main>
        <MediaBanner />
        <News />
        <Suspense fallback={<div>Loading additional information...</div>}>
          {/* <LatestTweets /> */}
          <Footer />
        </Suspense>
      </main>
    </>
  );
};

export default Mediapage;
