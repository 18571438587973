import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwentyTwo.scss";
import NewsPhotoTwentyTwo from "../../../assets/images/Photos/NewsPhotoTwentyTwo.png";

import Footer from "../../../components/Footer/Footer";

const ArticleTwentyTwo = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">July 25, 2024</span>
        <h3 className="article-page__title">
          B&K Enterprises Installing Concrete Barrier in Bucks County,
          Pennsylvania
        </h3>
        <p className="article-page__text">
          <br></br>
          At RSG International, we value the hard work that gets put into each
          project to enhance road safety, which is why we’re proud to spotlight
          a past project from B&K Enterprises, a division of RSG International,
          and their exemplary work for James D. Morrisey, Inc. starting in 2019.
          This was a long-term project that involved installing and removing
          temporary concrete barrier and replacing it with permanent concrete
          barrier on Route 309 in Bucks County, Pennsylvania years later.
          <br></br>
          <br></br>
          Beginning back in 2019, B&K Enterprises started on a significant
          project for James D. Morrisey, Inc. The crews at B&K started with
          deploying temporary concrete barrier, reducing the number of lanes,
          and creating a safer work zone for the construction workers doing
          their jobs in it. Once the temporary barrier was in place, the work
          crews were able to work within the confines of the work zone while
          traffic was managed in the newly reduced lanes. After the work was
          complete, B&K’s crew returned to the job site to remove the temporary
          concrete barrier and replace it with permanent concrete barrier; new
          infrastructure that would keep road users safe along Route 309.
          <br></br>
          <br></br>
          Over the course of the project B&K Enterprises’ crews had to adhere to
          strict timelines, coordinate with different stakeholders, and ensure
          that the transition from temporary to permanent was smooth for all
          involved. They were able to seamlessly phaseout the temporary barrier
          and install permanent concrete barrier in its place, marking a
          significant milestone and embodying our entire organization’s
          dedication to enhancing road safety. Great job, B&K Enterprises!
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTwentyTwo}
          alt="B&K Enterprises crew members installing concrete barrier in Bucks County, Pennsylvania."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/rsg-international-is-acquired-by-ramudden-global-bolstering-its-global-ambitions"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/national-traffic-awareness-month"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwentyTwo;
