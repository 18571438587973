import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwentyOne.scss";
import NewsPhotoTwentyOne from "../../../assets/images/Photos/NewsPhotoTwentyOne.JPG";

import Footer from "../../../components/Footer/Footer";

const ArticleTwentyOne = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">June 27, 2024</span>
        <h3 className="article-page__title">
          RSG International is Acquired by Ramudden Global, Bolstering Its
          Global Ambitions
        </h3>
        <p className="article-page__text">
          <br></br>
          In a groundbreaking transaction in the North American road safety
          infrastructure sector, RSG International, has been acquired by
          Ramudden Global, a Swedish multinational leader in safety
          infrastructure. This acquisition sets a new benchmark for deals in the
          sector, consolidating two key players in road safety infrastructure.
          <br></br>
          <br></br>
          “This acquisition marks a significant milestone for us, representing
          one of our largest endeavors to date. We are immensely excited about
          the prospects it offers, enabling us to broaden our horizons, achieve
          more, and elevate our performance to new heights,” explains Hans-Olov
          Blom, CEO of Ramudden Global.
          <br></br>
          <br></br>
          The strategic move strengthens Ramudden’s road safety offerings in
          North America, with both companies being leading providers of road
          safety infrastructure services including installation, product
          distribution, traffic management, and, notably, RSG International’s
          cutting-edge research and development division.
          <br></br>
          <br></br>
          “We’re thrilled to be part of Ramudden’s expansion into the North
          American market, as industry leaders this partnership will provide new
          opportunities to grow our market reach and better serve our customers
          while unlocking new, innovative opportunities,” explains Lisa Laronde,
          President of RSG International.
          <br></br>
          <br></br>
          While RSG International and its business units will remain a
          standalone division within the Ramudden Global family, this
          partnership will unlock collaboration opportunities and innovative
          possibilities, all aimed at enhancing infrastructure safety worldwide.
          Both companies are committed to cultivating diverse, inclusive
          workplaces and are aligned in their core values; continuing to
          contribute positively to their respective communities.
          <br></br>
          <br></br>
          Please read our FAQ below.
          <br></br>
          <br></br>
          <b className="bold-text">Frequently Asked Questions:</b>
          <br></br>
          <br></br>
          <b className="bold-text">What is Ramudden Global?</b>
          <br></br>
          <br></br>
          Ramudden Global is a global market leader in road safety
          infrastructure, the largest provider of traffic management and work
          zone safety services in the world. Based in Stockholm, Sweden, they
          have a presence across Europe and in Canada.
          <br></br>
          <br></br>
          <b className="bold-text">
            Why would Ramudden Global be interested in RSG International?
          </b>
          <br></br>
          <br></br>
          RSG International is a proven leader in the road safety infrastructure
          sector. With both RSG International and Ramudden Global interested in
          expanding across North America, the organizations are well aligned in
          terms of long-term strategic goals. Furthermore, the companies share
          similar values including prioritizing safety and people.
          <br></br>
          <br></br>
          <b className="bold-text">
            Is this acquisition part of a larger plan for RSG International?
          </b>
          <br></br>
          <br></br>
          Yes! This acquisition is part of RSG International’s plan for
          expansion across North America and beyond.
          <br></br>
          <br></br>
          With Ramudden Global’s support, the goal is to accelerate RSG
          International’s growth plans for North America. In addition, RSG
          International becoming part of a larger, global organization will lead
          to further growth opportunities going forward.
          <br></br>
          <br></br>
          <b className="bold-text">
            How will RSG International operate as part of Ramudden Global?
          </b>
          <br></br>
          <br></br>
          RSG International will retain its identity and function as a
          standalone subsidiary of Ramudden Global, operating much like it does.
          <br></br>
          However, an important part of the future for both organizations will
          be to build a collaborative working partnership, focused on sharing
          knowledge and best practices.
          <br></br>
          <br></br>
          <b className="bold-text">
            How will you ensure stability for current employees?
          </b>
          <br></br>
          <br></br>
          As part of this new partnership, it was important to ensure
          organizational stability, especially in terms of job security for RSG
          International’s employees. Day-to-day roles, reporting structure, and
          business objectives will all remain the same.
          <br></br>
          <br></br>
          Stability is a cornerstone of this partnership, a priority for all
          employees, and part of the reason RSG International will become a
          standalone division of Ramudden Global.
          <br></br>
          <br></br>
          <b className="bold-text">
            How will this affect RSG International’s culture and values?
          </b>
          <br></br>
          <br></br>
          RSG International’s culture and values will not change, as the company
          will continue operating as it always has apart from the change in
          ownership. RSG International will remain true to its roots but
          committed to evolving while collectively giving back to the community.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTwentyOne}
          alt="RSG International is acquired by Ramudden Global, bolstering its global ambitions."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/employee-spotlight-sharon-trac-at-powell-contracting"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/project-highlight-bk-enterprises-installing-concrete-barrier-in-bucks-county-pennsylvania"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwentyOne;
