import React from "react";
import { Link } from "react-router-dom";
import "./ArticleSeven.scss"; // Assuming you have a corresponding SCSS file for ArticleSeven
import NewsPhotoSeven from "../../../assets/images/Photos/NewsPhotoSeven.jpg"; // Update this if you have a different image for ArticleSeven

import Footer from "../../../components/Footer/Footer";

const ArticleSeven = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">Feb 9, 2024</span>
        <h3 className="article-page__title">
          Lisa Laronde Is Appointed to the Board of ORBA
        </h3>
        <p className="article-page__text">
          <br></br>
          Lisa Laronde, the first woman President of RSG International, a global
          leader in road safety infrastructure, has been named to the board of
          directors of the Ontario Road Builders’ Association (ORBA).
          <br></br>
          <br></br>
          “I believe this is an indication the industry is changing. Although I
          might be the only woman on the board today, we must start somewhere. I
          have a voice and I’m going to use it,” explains Lisa Laronde. “I think
          this is the beginning of something extraordinary.”
          <br></br>
          <br></br>
          The announcement was made during ORBA’s 97th Annual Convention and
          Annual General Meeting (AGM) held at the Fairmont Royal York in
          Toronto. This year's theme ‘Building Momentum: Engaging the Next
          Generation,’ underscores the organization's unwavering commitment to
          driving industry progress and aligning with Ontario’s growing and
          evolving infrastructure needs.
          <br></br>
          <br></br>
          “We are excited to welcome Lisa Laronde to ORBA’s board of directors
          where she joins leaders of Ontario’s transportation infrastructure
          industry,” said Walid Abou-Hamde the CEO of ORBA. “Lisa’s versatile
          skillset and experience will support the Association in implementing
          its strategic plan and advancing its members’ interests.”
          <br></br>
          <br></br>
          Laronde was recently recognized as one of Canada’s ‘Top 100 Most
          Powerful Women’, she’s also the President of the Canadian Association
          of Women in Construction (CAWIC) and is widely known as a fierce
          advocate for women in leadership. Laronde is passionate about building
          psychologically safe work environments where diversity and inclusion
          are at the forefront of any organization she’s a part of.
          <br></br>
          <br></br>
          “Lisa (Laronde) is a trailblazer who breaks barriers and paves the way
          for others to follow, her appointment to the Ontario Road Builders’
          Association is a testament to her exceptional leadership and a beacon
          for the future of women in the industry,” explains Jamie West a Board
          Member at CAWIC and President of Peninsula Construction, a division of
          RSG International.
          <br></br>
          <br></br>
          Please read our press release{" "}
          <a
            href="https://www.prnewswire.com/news-releases/lisa-larondes-appointment-to-the-orba-board-of-directors-brings-a-unique-perspective-302058385.html"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            here
          </a>
          .<br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoSeven}
          alt="Lisa Laronde at the ORBA board announcement event."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/mark-ayton-from-safe-roads-research-development-becomes-the-first-canadian-to-win-a-lifetime-award-from-the-transportation-research-board"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/jason-spencer-joins-rsg-internationals-executive-team"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleSeven;
