import React, { Suspense } from "react";
import "./AboutPage.scss";

import AboutUs from "./AboutUs/AboutUs";
import WhatWeDo from "./WhatWeDo/WhatWeDo";


const WhoWeAre = React.lazy(() => import("./RSGWhoWeAre/RSGWhoWeAre"));
const OurStory = React.lazy(() => import("./OurStory/OurStory"));
const LandAcknowledgement = React.lazy(() => import("./LandAcknowledgement/LandAcknowledgement"));
const OurTeam = React.lazy(() => import("./OurTeam/OurTeam"));
const Footer = React.lazy(() => import("../../components/Footer/Footer"));

const Aboutpage = () => {
  return (
    <>
      <main>
        <AboutUs />
        <WhatWeDo />
        <Suspense fallback={<div>Loading...</div>}>
          <WhoWeAre />
          <OurStory />
          <LandAcknowledgement />
          <OurTeam />
          <Footer />
        </Suspense>
      </main>
    </>
  );
};

export default Aboutpage;
