import React from "react";
import { Link } from "react-router-dom";
import "./ArticleEleven.scss";
import NewsPhotoEleven from "../../../assets/images/Photos/NewsPhotoEleven.jpeg";

import Footer from "../../../components/Footer/Footer";

const ArticleTen = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">April 17, 2024</span>
        <h3 className="article-page__title">
          Canada's First Teledriven Crash Truck Debuted at Intertraffic
        </h3>
        <p className="article-page__text">
          <br></br>
          The debut of Canada’s first tele-driven crash truck is set to take
          place at Intertraffic Amsterdam, the world’s premier international
          trade fair dedicated to traffic infrastructure, management, road
          safety, and parking.
          <br></br>
          <br></br>
          RSG International, a global leader in road safety infrastructure,
          recently inked a licensing agreement with Elmo, the Estonian company
          behind groundbreaking road legal tele-driving technology. Significant
          progress has been made after an early Christmas gift in the form of a
          Ford F550 that arrived last year in Estonia from Canada. Since then,
          the truck has been retrofitted and will soon be on display in the
          North American market.
          <br></br>
          <br></br>
          "This project hasn't been without its hurdles. While we've had prior
          experience and were proficient in upgrading electrical vehicles in
          past projects, the Ford F550 posed unique challenges with its
          hydraulic power steering and braking system. However, after overcoming
          some trial and error, our team of great engineers successfully adapted
          our technology. Now, we possess the necessary skills and experience to
          retrofit an entire fleet of crash trucks,” adds Enn Laansoo, Jr., CEO
          and Founder of Elmo.
          <br></br>
          <br></br>
          The technology will have a live presentation at Intertraffic demo area
          D1. The vehicle, which is still in Tallinn, will be driven remotely
          from Amsterdam by a licensed tele-driver under the Estonian Traffic
          Department.
          <br></br>
          <br></br>
          The truck can be taken throughout the European Union for promotions
          until May when it will be delivered back to Canada.
          <br></br>
          <br></br>
          “The integration of this technology would in theory allow us to remove
          drivers from crash truck vehicles, ensuring their safety during
          operations. Our research and development team are eager to do our
          testing to see if tele-driving will be a good fit for the road and
          construction industry across Canada,” explains Elliot Steele, Vice
          President, Technology at RSG International.
          <br></br>
          <br></br>
          The live presentation of the trek-driven Ford F550 crash truck in the
          Netherlands is organized together with Dutch Automated Mobility (DAM),
          a pioneer in autonomous vehicle technology. The partnership agreement
          with DAM also includes teledriving technology promotion and licensing
          in Netherland market.
          <br></br>
          <br></br>
          Elmo’s road legal tele-driving technology has been used in public
          car-sharing services since 2022 and is legal today in 3 countries. The
          company, which is developing similar technology for military use, has
          run testing and started permitting in Germany, France, Switzerland,
          Austria, the Netherlands, the US, and other countries.
          <br></br>
          <br></br>
          Please read our press release{" "}
          <a
            href="https://tech.einnews.com/amp/pr_news/703829242/tele-driving-breakthrough-canadian-crash-truck-makes-global-debut-in-amsterdam"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            here
          </a>
          .<br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoEleven}
          alt="Attendees of a conference testing out the new teledriven truck system."
        />
        

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/president-of-rsg-international-launches-podcast-on-international-womens-day"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/lisa-laronde-president-of-rsg-international-has-been-named-one-of-the-most-influential-people-of-2024-by-sitenews"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTen;
