import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwo.scss";
import NewsPhotoOne from "../../../assets/images/Photos/LatestUpdatesPhoto1.jpg";

import Footer from "../../../components/Footer/Footer";

const ArticleTwo = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">Oct 3rd, 2023</span>
        <h3 className="article-page__title">
          Moving Road Safety Forward Through Innovation with Elmo
        </h3>
        <p className="article-page__text">
          We are pleased to announce our new partnership with{" "}
          <a
            href="https://www.elmoremote.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            Elmo
          </a>
          , the Estonian company behind the world´s first road-legal teledriving
          technology in operation. This new agreement underscores our dedication
          to innovation in road safety, constantly seeking new ways to keep road
          users safe everywhere. Elmo’s teledriving platform presents an
          excellent opportunity to test novel technology in the road safety
          sector, in particular when it comes to retrofitting our crash trucks,
          which can now be operated remotely.
          <br />
          <br />
          “We have tested self-driving vehicles, but teledriving is a much more
          effective solution for the road safety sector, both today and in the
          future. When Elmo picked us up at the airport in their teledriven car
          and drove us to the hotel, we immediately understood that this was the
          company we wanted to partner with,” stated RSG International’s Vice
          President of Technology, Elliot Steele. As leaders in the road safety
          sector, we are always looking for cutting-edge solutions to help drive
          our mission forward.
          <br />
          <br />
          Read our press release{" "}
          <a
            href="https://www.einpresswire.com/article/659358989/global-road-safety-leader-rsg-international-partners-with-teledriving-innovator-elmo"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            here
          </a>
          .
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoOne}
          alt="A very large truck, holding a smaller truck, holding equipment, driving down a road."
        />
        <div className="article-navigation">
          <Link
            to="/observing-the-national-day-for-truth-and-reconciliation"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/rsg-international-strikes-a-new-strategic-partnership"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwo;
