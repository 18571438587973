import React from "react";
import { Link } from "react-router-dom";
import "./ArticleThree.scss";
import NewsPhotoThree from "../../../assets/images/Photos/WelcomeBannerMobile.jpg";

import Footer from "../../../components/Footer/Footer";

const ArticleThree = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">Oct 5, 2023</span>{" "}
        {/* Adjust date */}
        <h3 className="article-page__title">
        RSG International Strikes a New Strategic Partnership
        </h3>
        <p className="article-page__text">
        We’re very excited to announce a new strategic partnership
                between RSG International and{" "}
          <a
            href="https://www.markscommercial.com/en-ca/"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            Mark’s Commercial
          </a> focused on developing our safety-first work environment. Mark’s Commercial is Canada’s leading supplier of workwear, footwear, personal protective equipment (PPE), safety gear, and uniform programs, and understands our culture of safety and investment in innovative equipment for employees.
          <br></br><br></br>
          This collaboration will help us to ensure the safety of our workforce, specifically with a tailored safety package for our employees. They will offer a tailored safety package for our workforce, including their male and true female fit 5-pt tearaway safety vest with the innovative VizLite DT technology that increases visibility of the wearer by up to 200 metres.
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoThree}
          alt="A very large truck, holding a smaller truck, holding equipment, driving down a road."
        />
        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link to="/moving-road-safety-forward-through-innovation-with-elmo" className="nav-button">
            Previous
          </Link>
          <Link to="/lisa-laronde-wxn-2023-canadas-most-powerful-women" className="nav-button">
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleThree;
