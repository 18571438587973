import React from "react";
import { Link } from "react-router-dom";
import "./ArticleThirteen.scss";
import NewsPhotoThirteen from "../../../assets/images/Photos/NewsPhotoThirteen.jpg";

import Footer from "../../../components/Footer/Footer";

const ArticleThirteen = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">May 07, 2024</span>
        <h3 className="article-page__title">
          Recognizing Mental Health Awareness Month
        </h3>
        <p className="article-page__text">
          <br></br>
          At RSG International, we are fully committed to supporting Mental
          Health Awareness Month this May. We need to overcome the stigma that
          frequently surrounds mental health concerns and commit to supporting
          one another. The construction industry in particular has made strides
          towards improving employee wellness, through new initiatives, employee
          programs, and more, but has a long way to go.
          <br></br>
          <br></br>
          This month, our employees will participate in multiple workshops and
          other activities that deepen our understanding of mental health. We're
          working to create a work environment where everyone feels empowered to
          speak up and encouraged to succeed. We have set up strong resources
          and support networks to ensure that every member of our team can get
          the support they need.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoThirteen}
          alt="Workers on a road construction site."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/lisa-laronde-president-of-rsg-international-has-been-named-one-of-the-most-influential-people-of-2024-by-sitenews"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/chantal-seguin-elected-president-of-the-simcoe-county-heavy-construction-association"
            className="nav-button"
          >
            Next
          </Link>
        </div>
        
      </div>
      <Footer />
    </>
  );
};

export default ArticleThirteen;
