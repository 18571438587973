import React from "react";
import { Link } from "react-router-dom";
import "./ArticleEight.scss";
import NewsPhotoEight from "../../../assets/images/Photos/NewsPhotoEight.jpg"; // Update this if you have a different image for ArticleEight

import Footer from "../../../components/Footer/Footer";

const ArticleEight = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">February 22, 2024</span>
        <h3 className="article-page__title">
          Jason Spencer Joins RSG International’s Executive Team
        </h3>
        <p className="article-page__text">
          <br></br>
          We’re very pleased to welcome Jason Spencer to our executive team. A
          seasoned leader, Spencer will be responsible for developing and
          implementing strategic initiatives to drive growth, enhance
          competitiveness, and optimize overall performance across all its
          business units.
          <br></br>
          <br></br>
          “Jason brings diverse perspectives and expertise to the table, and my
          executive and senior leadership teams are looking forward to
          collaborating with him, as he helps propel the organization forward
          and we continue to lead and innovate in the road safety industry,”
          explains Lisa Laronde, President of RSG International.
          <br></br>
          <br></br>
          Spencer is currently the President of EKHO Infrastructure Solutions
          and Durisol North America, which specialize in precast concrete
          infrastructure products, including sound and retaining wall products.
          Spencer has a proven track record of achievement in the construction
          materials supply sector, including expanding operations in Canada and
          the U.S. In addition, he is a seasoned leader who excels in
          negotiation, budgeting, management, and business development.
          <br></br>
          <br></br>
          “I’m looking forward to this journey with a new leadership team. I am
          eager to contribute, collaborate, and drive success together,” adds
          Jason Spencer.
          <br></br>
          <br></br>
          Spencer steps into his new role as a Director at RSG International on
          March 4th, 2024.
          <br></br>
          <br></br>
          Please read our press release{" "}
          <a
            href="https://www.einpresswire.com/article/690684494/rsg-international-welcomes-new-executive-jason-spencer-tasked-with-driving-strategic-growth"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            here
          </a>
          .<br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoEight}
          alt="Jason Spencer at the RSG International executive team introduction event."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/lisa-laronde-is-appointed-to-the-board-of-orba"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/celebrating-women-in-construction-week"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleEight;
