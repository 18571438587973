import React from "react";
import "./AccessibilityPlan.scss";

import Footer from "../../../components/Footer/Footer";

const AccessibilityPlan = () => {
  return (
    <>
      <div className="accessibilityplan-banner">
        <div className="accessibilityplan-banner__container">
          <h1 className="accessibilityplan-banner__title">Accessibility Plan</h1>
        </div>
      </div>
      <div className="accessibility-plan-container">
        <div>
          <h1 className="plan-title">Multi-Year Accessibility Plan</h1>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">Statement of Commitment</h2>
          <p className="plan-text">
            RSG International is committed to providing a barrier-free
            environment for all stakeholders, including clients, employees, job
            applicants, suppliers, and any visitors who enter the premises, work
            for the company, access information provided by the company, or use
            the company’s goods and services. RSG International is committed to
            complying with the regulations and standards set forth by the
            Accessibility for Ontarians with Disabilities Act, 2005 (AODA).
            <br></br>
            <br></br>
            RSG International will work to break down the barriers that prevent
            or limit persons with disabilities from employment, receipt of goods
            and services, the built environment, and information and
            communication through the implementation of accessibility standards.
          </p>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">
            Accessibility Plan and Policies for: RSG International
          </h2>
          <p className="plan-text">
            The Multi-Year Accessibility Plan outlines the strategy that RSG
            International has put in place to improve opportunities for persons
            with disabilities under the AODA standards and regulations. The
            Multi-Year Accessibility Plan will help RSG International to become
            a more accessible employer and service provider.
            <br></br>
            <br></br>
            As an organization, we will review our Multi-Year Accessibility Plan
            on a yearly basis to ensure all objectives are carried out in a
            timely manner and we will update the Accessibility Plan as
            necessary.
          </p>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">Accessible Emergency Information</h2>
          <p className="plan-text">
            Where required, RSG International will create individualized
            workplace emergency response plans for employees with disabilities.
            The emergency response plan will consider the unique challenges
            created by the individual’s disability and the physical nature of
            the workplace and will be created in consultation with the employee.
            If an employee with a disability requires assistance from a support
            person during an emergency, the company will designate a fellow
            employee to act as such.
          </p>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">Training</h2>
          <p className="plan-text">
            RSG International recognizes that skills development and career
            planning can enrich the employment experience, increase engagement,
            and reduce turnover for individuals with disabilities. The company
            will align training and development programs to meet the needs of
            employees with disabilities and provide training as soon as
            reasonably practicable upon the person being assigned to applicable
            duties. Training programs will be designed with flexibility to allow
            customization for the individual requirements of the employee, such
            as providing training materials in accessible or conversion-ready
            formats that take into account the need of the employee. The company
            will consider employee barriers when implementing performance
            management processes, or when offering career development,
            employment support, or advancement opportunities.
          </p>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">Information & Communications</h2>
          <p className="plan-text">
            RSG International understands the importance of accessible digital
            and non-digital forms of communication and will provide accessible
            types of communication and information to all individuals with
            disabilities. Such accessible formats and communication supports
            will be provided upon request, in a timely manner and at no
            additional cost.
          </p>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">Feedback</h2>
          <p className="plan-text">
            RSG International will ensure that feedback can be provided by
            customers with disabilities through a variety of mechanisms, such as
            in person, by phone, e-mail, website, or social media. Feedback
            forms, along with alternate methods of providing feedback verbally
            (in person or by telephone) or written (handwritten, delivered,
            website, or e-mail), will be available upon request. Customers who
            provide formal feedback will receive acknowledgment of their
            feedback, along with any resulting actions based on concerns or
            complaints that were submitted.
          </p>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">Building Accessibility</h2>
          <p className="plan-text">
            RSG International will work to ensure that the built environment,
            including building interior and exterior, are designed to facilitate
            barrier-free access to goods or services for customers and
            employees. If areas of the built environment are not accessible for
            certain individuals with disabilities, the company will work with
            the individual to provide an alternate means of access. By 2025, RSG
            International will address any identified immediate and low-cost
            barriers and adjust to improve accessibility.
          </p>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">Website Accessibility</h2>
          <p className="plan-text">
            We will ensure that all web content will conform to WCAG 2.0, Level
            AA through AccessiBe. This technology will use machine learning
            algorithms to analyze and modify website content to make it more
            accessible.
          </p>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">Accommodation</h2>
          <p className="plan-text">
            RSG International will provide individual accommodations to support
            the needs of employees with disabilities. Personalized accommodation
            plans will be designed to allow employees to contribute and
            participate in job-related functions and activities to the best of
            their abilities. The process of accommodating individuals will take
            a consultative approach and is a shared obligation of the company,
            the employee, and any applicable professionals required to assist
            the employee throughout the process.
          </p>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">Employment</h2>
          <p className="plan-text">
            RSG International will make every effort to identify, remove, and
            prevent barriers by developing inclusive employment procedures that
            support persons with disabilities with accommodations during the
            recruitment and selection stages, and throughout the employment life
            cycle.
            <br></br>
            <br></br>
            We will take the following steps to ensure the accessibility needs
            of employees with disabilities are considered when using performance
            management, career development and redeployment processes:
            <br></br>
            <br></br>
          </p>
          <ul className="plan-list">
            <li>
              Will provide documents related to performance management, such as
              performance plans, available in accessible formats upon request.
            </li>
            <li>
              Review an employee’s individual accommodation plan to understand
              the employee’s accommodation needs and determine whether it needs
              adjusting to improve their performance on the job.
            </li>
            <li>
              Review an employee’s individual accommodation plan to understand
              the adjustments that may be needed for new responsibilities with
              regards to career development and advancement opportunities.
            </li>
            <li>
              Provide informal and formal coaching and feedback where necessary
              in a manner that considers an employee’s disability.
            </li>
            <li>
              Consider the accessibility needs of employees with disabilities
              when moving them to other positions or projects, so that employees
              can continue to have their accommodation needs met.
            </li>
          </ul>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">Recruitment and Hiring</h2>
          <p className="plan-text">
            RSG International understands that improving workplace accessibility
            and ensuring an accessible recruitment and selection process for
            applicants with disabilities can expand the pool of talented
            candidates. Upon request, the company will provide candidates with
            reasonable accommodations during the interview and selection
            process. Where an accommodation is requested, the company will
            consult with the applicant to arrange suitable accommodations, such
            as providing the application in an alternate or accessible format.
            <br></br>
            <br></br>
            The company interview process will focus on experience and skills
            and will not discriminate against candidates who have a disability
            or require an accommodation. RSG International is committed to
            hiring decisions that are unbiased and based on qualifications and
            experience. Successful candidates will be made aware of policies and
            supports for accommodations upon completion of the recruitment
            process.
          </p>
        </div>

        <div className="plan-section">
          <h2 className="plan-subtitle">For More Information</h2>
          <p className="plan-text">
            For more information on this accessibility plan, please contact our
            Human Resources Department at 905-727-2518 or by email at{" "}
            <a href="mailto:hr@rsgint.com" className="plan-link">
              hr@rsgint.com
            </a>
            <br></br>
            <br></br>
            This document can be made available in an accessible format upon
            request.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccessibilityPlan;
