import React from "react";
import { Link } from "react-router-dom";
import "./ArticleEighteen.scss";
import NewsPhotoEighteen from "../../../assets/images/Photos/NewsPhotoEighteen.jpg";

import Footer from "../../../components/Footer/Footer";

const ArticleEighteen = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">June 5, 2024</span>
        <h3 className="article-page__title">
          National Indigenous History Month: Celebrating Indigenous Cultures
        </h3>
        <p className="article-page__text">
          <br></br>
          National Indigenous History Month gives us the chance to learn about
          the unique cultures and traditions of First Nations, Métis, and Inuit
          communities. By understanding their history, we can work towards
          reconciliation and a more inclusive future. With Indigenous voices
          continuing to inspire positive change.
          <br></br>
          <br></br>
          Properly educating ourselves and providing safe spaces for open
          discussion is the essence of what National Indigenous History Month is
          all about. This month, and every month, RSG International stands with
          Indigenous communities across Canada and recognizes the important role
          they have played in building the Canada we know today. Whether we’re
          talking about past or present injustices, we will continue to raise
          awareness of the issues these communities face.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoEighteen}
          alt="National Indigenous History Month poster."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/celebrating-pride-month-at-rsg-international"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/rsg-international-named-one-of-canadas-best-places-to-work"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleEighteen;
