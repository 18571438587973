import "./WhistleblowerPolicy.scss";

import Footer from '../../components/Footer/Footer';


function WhistleblowerPolicy() {
  return (
    <>
    <div className="whistleblower-container">
      <h1 className="whistleblower-title">WHISTLEBLOWER POLICY</h1>
      <h2 className="whistleblower-subtitle">I. Purpose</h2>
      <p className="whistleblower-text">
        This policy is established to provide a safe and confidential, or
        anonymous mechanism for individuals and stakeholder groups to report
        concerns about illegal, unethical, or unsafe business practices within
        RSG International without fear of retaliation or repercussions.
      </p>
      <h2 className="whistleblower-subtitle">II. Scope</h2>
      <p className="whistleblower-text">
        This policy applies to all stakeholder groups, including board members,
        employees, contractors, suppliers, vendors, volunteers, and other
        members of the public involved with RSG International, its affiliated
        companies, or a related company or organization.
      </p>
      <h2 className="whistleblower-subtitle">III. Protected Disclosures</h2>
      <p className="whistleblower-text">
        Individuals and stakeholder groups are encouraged to report what they,
        in good faith, believe to be evidence of:
      </p>
      <ul>
        <li className="whistleblower-list">
          A violation of any law, act, or regulation
        </li>
        <li className="whistleblower-list">
          Financial malpractice or gross waste of funds
        </li>
        <li className="whistleblower-list">Gross mismanagement</li>
        <li className="whistleblower-list">Abuse of authority</li>
        <li className="whistleblower-list">Unethical business conduct</li>
        <li className="whistleblower-list">
          Dangerous health and safety violations
        </li>
        <li className="whistleblower-list">
          Appearance of a conflict of interest
        </li>
        <li className="whistleblower-list">
          Serious breach of our code of conduct
        </li>
        <li className="whistleblower-list">
          Discrimination based on prohibited grounds
        </li>
        <li className="whistleblower-list">
          Knowingly directing or counseling a person to commit wrongdoing
        </li>
        <li className="whistleblower-list">
          Corruption of foreign public officials
        </li>
        <li className="whistleblower-list">Matters in the public interest</li>
        <li className="whistleblower-list">Reports of emergencies</li>
        <li className="whistleblower-list">
          Any other serious improper conduct
        </li>
      </ul>
      <p className="whistleblower-text">
        Disclosures should be submitted using one of the channels listed below.
        Supporting evidence should be provided wherever possible, including
        photographs, audio and video recordings, financial records, receipts,
        emails, electronic communications, and/or any other correspondence.
      </p>
      <p className="whistleblower-text">
        In certain jurisdictions, disclosures to journalists and lawmakers are
        also considered protected under specific circumstances. RSG
        International does not recommend using these disclosure pathways, as
        public disclosures that are deemed non-protected can lead to
        termination. If you disclose your concerns to the public in another way
        (such as social media), whistleblower protections do not apply.
      </p>
      <h2 className="whistleblower-subtitle">IV. Standard Grievances</h2>
      <p className="whistleblower-text">
        Grievances are real or perceived issues affecting the wellbeing or
        happiness of an employee. They typically relate to unsatisfactory
        working conditions, disputes relating to payment or benefits, scheduling
        and hours of work, or instances of workplace bullying, harassment, or
        discrimination. Employee grievances should not be submitted to the
        whistleblower reporting program. Rather, they should be brought to the
        attention of a supervisor, boss or representative from HR.
      </p>
      <h2 className="whistleblower-subtitle">V. Reporting Channels</h2>
      <p className="whistleblower-text">
        Whistleblower submissions can be made via:
      </p>
      <ul>

        <li className="whistleblower-list">Company Staff</li>
        <li className="whistleblower-list--sublist">Officers</li>
        <li className="whistleblower-list--sublist">Directors</li>
        <li className="whistleblower-list--sublist">Secretaries</li>
        <li className="whistleblower-list--sublist">Senior managers</li>
        <li className="whistleblower-list--sublist">Actuaries</li>
        <li className="whistleblower-list--sublist">Auditors (or a member of the audit team)</li>
        <br></br>
        {/* <li className="whistleblower-list">
        Dedicated Hotline
        </li>
        <li className="whistleblower-list--sublist">
        1-800-XXX-XXXX
        </li>
        <li className="whistleblower-list--sublist">
        905-XXX-XXXX
        </li> */}
        <br></br>
        <li className="whistleblower-list">
        Mail:
        </li>
        <p className="whistleblower-list">
        Attn: Whistleblower Protection Officer<br></br>
        180 Ram Forest Rd.<br></br>
        Stouffville, Ontario, L4A 2G8
        </p>
        <br></br>
        <li className="whistleblower-list">
        Email: whistleblower@rsgint.com
        </li>
        <li className="whistleblower-list">Online Portal: https://rsgint.com/whistleblower/</li>
      </ul>
      <h2 className="whistleblower-subtitle">VI. Confidentiality</h2>
      <p className="whistleblower-text">
        RSG International goes to great lengths to protect the identity of
        whistleblowers. Once RSG International acknowledges receipt of a
        whistleblower submission, an individual's identity will only be revealed
        with their express, written consent. If the information provided relates
        to a possible offence, a whistleblower’s identity can be used to inform
        a regulatory body, government agency or law enforcement authority.
      </p>
      <p className="whistleblower-text">
        Non-protected disclosures acknowledged by RSG International are also
        kept strictly confidential. Should the identity of a whistleblower
        become known in the workplace, measures will be taken to prevent
        potential reprisals. This could include reassigning the whistleblower to
        other duties or transferring their employment to another subsidiary (on
        a temporary or permanent basis).
      </p>
      <h2 className="whistleblower-subtitle">VII. Anonymous Submissions</h2>
      <p className="whistleblower-text">
        Anonymous submissions are also accepted under this policy. However, RSG
        International is unable to provide acknowledgements, request additional
        information, share updates and findings, or offer legal protections to
        those who utilize this option (except for Australia).
      </p>
      <h2 className="whistleblower-subtitle">
        VIII. Updates & Acknowledgements
      </h2>
      <ul>
        <li className="whistleblower-list">
          Acknowledgment: Confirmation of receipt will be provided within 2
          business days of RSG International receiving a whistleblower
          submission.
        </li>
        <li className="whistleblower-list">
          Investigation: A preliminary review will determine whether a
          whistleblower submission meets the criteria for a protected
          disclosure. An update with the initial determination will be provided
          to the whistleblower within 30 business days.
        </li>
        <li className="whistleblower-list">
          Updates: Investigations will be conducted by an impartial committee or
          external third party as needed. Whistleblowers will be invited to
          provide additional context, information and evidence at this stage.
          Periodic updates will be provided throughout the process.
        </li>
        <li className="whistleblower-list">
          Outcomes: Where possible, the whistleblower will be informed about the
          outcome of an investigation. Exceptions may apply if the information
          is deemed privileged, confidential or sensitive for national security
          reasons.
        </li>
      </ul>
      <h2 className="whistleblower-subtitle">IX. Non-Retaliation</h2>
      <p className="whistleblower-text">
        In accordance with applicable laws, RSG International prohibits
        retaliation against whistleblowers that disclose in good faith.
        Retaliation includes, but is not limited to, layoffs, demotions,
        suspensions, financial penalties, disciplinary or legal action, personal
        attacks, or threats of any kind. These protections also extend to the
        spouses, relatives and dependents of whistleblowers.
      </p>
      <p className="whistleblower-text">
        In extreme cases, abuse of RSG International’s Whistleblower Policy and
        Submission Platform can lead to corrective action. Stakeholders caught
        submitting fraudulent statements, providing false testimony or
        fabricating data in an attempt to incriminate others can be subject to
        disciplinary and/or legal action.
      </p>
      <h2 className="whistleblower-subtitle">
        X. Submitting a Retaliation Complaint
      </h2>
      <p className="whistleblower-text">
        If you are an employee of RSG International (one of its subsidiaries, an
        affiliated company or organization), you can submit a retaliation
        complaint to Human Resources if you received an acknowledgement of a
        whistleblower submission. You may also decide to file a union grievance,
        if applicable. Please consult with your designated union representative
        for further guidance. Anonymous whistleblowers are not protected and
        cannot submit retaliation complaints under this policy (except for
        Australia).
      </p>
      <h2 className="whistleblower-subtitle">XI. Roles and Responsibilities</h2>
      <ul>
        <li className="whistleblower-list">
          Whistleblower Protection Officer: Appointed individual responsible for
          ensuring the policy's implementation and the protection of
          whistleblowers.
        </li>
        <li className="whistleblower-list">
          Investigation Committee: Responsible for conducting fair and unbiased
          investigations of reported concerns.
        </li>
        <li className="whistleblower-list">
          Retaliation Complaint Officer: Responsible for working with HR and/or
          a union representatives to evaluate the merits of a retaliation
          complaint submitted by a whistleblower.
        </li>
      </ul>
      <h2 className="whistleblower-subtitle">
        XII. Training and Communication
      </h2>
      <p className="whistleblower-text">
        Regular training will be provided to employees about their rights and
        responsibilities under this policy. The policy will be communicated
        through internal communication channels and made available on the
        organization’s website and enterprise intranet platform.
      </p>
      <h2 className="whistleblower-subtitle">XIII. Policy Review</h2>
      <p className="whistleblower-text">
        This policy will be reviewed annually to ensure its effectiveness and
        compliance with applicable laws and regulations.
      </p>
      <h2 className="whistleblower-subtitle">XIV. Acknowledgment of Policy</h2>
      <p className="whistleblower-text">
        I acknowledgment that I have read, understood, and agree to comply with
        the terms of this policy.
      </p>
      <p className="whistleblower-text"> 
      Please access our Online Whistleblower Platform{" "}
          <a
            href="https://wl229wl9lg2.typeform.com/to/o4BHKbMT?typeform-source=mail.google.com"
            target="_blank"
            rel="noopener noreferrer"
            className="whistleblower-link"
          >
             here
          </a>.</p>
    </div>
    <Footer />
    </>
  );
}

export default WhistleblowerPolicy;
