import React, { Suspense } from 'react';
import "./HomePage.scss";

import WelcomeBanner from './WelcomeBanner/WelcomeBanner';
import WorkersBanner from './WorkersBanner/WorkersBanner';
import RoadSafety from "./RoadSafety/RoadSafety";
// Lazy load the below-the-fold components
const SafetyInfrastructure = React.lazy(() => import('./SafetyInfrastructure/SafetyInfrastructure'));
const LatestUpdates = React.lazy(() => import('./LatestUpdates/LatestUpdates'));
const ConstructionBanner = React.lazy(() => import('./ConstructionBanner/ConstructionBanner'));
const Footer = React.lazy(() => import('../../components/Footer/Footer'));

const Homepage = () => {
  return (
    <>
      <main>
        <WelcomeBanner />
        <WorkersBanner />
        <RoadSafety />
        <Suspense fallback={<div>Loading section...</div>}>
          <SafetyInfrastructure />
          <LatestUpdates />
          <ConstructionBanner />
          <Footer />
        </Suspense>
      </main>
    </>
  );
};

export default Homepage;
