import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwelve.scss";
import NewsPhotoTwelve from "../../../assets/images/Photos/NewsPhotoTwelve.jpg";

import Footer from "../../../components/Footer/Footer";

const ArticleTwelve = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">April 23, 2024</span>
        <h3 className="article-page__title">
          Lisa Laronde Named to Most Influential People in Construction 2024
        </h3>
        <p className="article-page__text">
          <br></br>
          We are excited to share that Lisa Laronde, President of RSG
          International, has been named one of the Most Influential People of
          2024 by SiteNews. This recognition is awarded to leaders who
          significantly impact the construction industry through innovation,
          strong leadership, and dedication.
          <br></br>
          <br></br>
          Lisa's accomplishment demonstrates how she has advanced the industry.
          Her devotion to excellence has not only helped RSG International grow
          but has also raised the bar for the construction industry. Her
          influence makes her a passionate advocate for workforce development,
          believing strongly in empowering employees through continuous
          education and professional growth opportunities.
          <br></br>
          <br></br>
          Receiving this award from Site News Construction celebrates Lisa's
          personal contributions and reinforces RSG International's role as a
          leader in the construction sector.
          <br></br>
          <br></br>
          Congratulations to Lisa on this well-deserved recognition. Her vision
          and dedication continue to inspire all of us at RSG International in
          everything we do.
          <br></br>
          <br></br>
          Please read our press release{" "}
          <a
            href="https://www.einpresswire.com/article/705902855/rsg-international-s-president-lisa-laronde-recognized-among-construction-s-most-influential-people"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            here
          </a>
          .<br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTwelve}
          alt="Lisa Laronde standing with the SiteNews award for Most Influential People of 2024."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/canadas-first-teledriven-crash-truck-debuted-at-intertraffic"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/recognizing-mental-health-awareness-month"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwelve;
