import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwentyFive.scss";
import NewsPhotoTwentyFive from "../../../assets/images/Photos/NewsPhotoTwentyFive.png";

import Footer from "../../../components/Footer/Footer";

const ArticleTwentyFive = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">September 20, 2024</span>
        <h3 className="article-page__title">
          RSG International Celebrates National Construction Appreciation Week
        </h3>
        <p className="article-page__text">
          At RSG International, we understand the tremendous effort and
          dedication that goes into every construction project. National
          Construction Appreciation Week is an opportunity to recognize the
          hard-working individuals who build and maintain our communities. From
          highways to commercial buildings, their contributions shape our world
          and drive progress forward.
          <br></br>
          <br></br>
          As we close out the week, we want to express our deepest gratitude to
          all our employees and partners who ensure that every job is completed
          with excellence. Your hard work and resilience are the foundation of
          our success at RSG International. We remain committed to supporting
          you with the tools, safety measures, and opportunities to thrive in
          our industry. Thank you for your dedication to making our roads safer!
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTwentyFive}
          alt="Construction workers on a highway safely installing barriers."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link to="/how-to-save-on-construction-costs-with-used-concrete-barriers" className="nav-button">
            Previous
          </Link>
          <Link
            to="/national-day-for-truth-and-reconciliation-2024"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwentyFive;
