import React from "react";
import { Link } from "react-router-dom";
import "./ArticleSeventeen.scss";
import NewsPhotoSeventeen from "../../../assets/images/Photos/NewsPhotoSeventeen.jpg";

import Footer from "../../../components/Footer/Footer";

const ArticleSeventeen = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">June 3, 2024</span>
        <h3 className="article-page__title">
          Celebrating Pride Month at RSG International
        </h3>
        <p className="article-page__text">
          <br></br>
          At RSG International, we are proud to celebrate Pride Month this June,
          honouring the 2SLGBTQIA+ community and their ongoing fight for
          equality and inclusion. This month, along with celebrating and showing
          support, we reflect on the importance of creating welcoming and
          accepting spaces in the workplace and beyond.
          <br></br>
          <br></br>
          We believe in standing up for one another, treating everyone with
          equality and respect, and allowing for all of our employees to be
          surrounded in an environment where they feel supported and valued.
          Creating an inclusive environment, where everyone feels valued and
          respected, has been and will continue to be a priority for us.
          Furthermore, we believe it’s an important time to look back at the
          history of the Pride movement and how far we’ve come as a society in
          this fight for civil rights.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoSeventeen}
          alt="A Pride Flag proudly waving in the blue sky."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/rsg-international-lights-the-path-for-pink-titans-robotics-in-2025"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/national-indigenous-history-month-celebrating-indigenous-cultures"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleSeventeen;
