import React from "react";
import { Link } from "react-router-dom";
import "./ArticleNineteen.scss";
import NewsPhotoNineteen from "../../../assets/images/Photos/NewsPhotoNineteen.png";

import Footer from "../../../components/Footer/Footer";

const ArticleNineteen = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">June 11, 2024</span>
        <h3 className="article-page__title">
          RSG International Named One of Canada’s Best Places to Work
        </h3>
        <p className="article-page__text">
          <br></br>
          RSG International has been recognized as one of the best places to
          work in Canada by Human Resources Director (HRD) magazine. The award
          celebrates companies that go beyond just ensuring employee happiness
          by prioritizing their needs and providing them with the tools and
          resources for success.
          <br></br>
          <br></br>
          As a leader in the road safety infrastructure industry, RSG
          International is composed of multiple business units across Canada and
          the United States. The organization is dedicated to creating work
          environments that are both physically and psychologically safe,
          reflecting its core values and commitment to employee well-being.
          <br></br>
          <br></br>
          “This award would not have been possible without the tireless efforts
          of our Human Resources team, who work hard to ensure that our
          employees can share ideas, feel supported, and are empowered and
          valued,” said Paul Stewart, Director of Human Resources at RSG
          International. “As we celebrate this significant achievement, we are
          committed to building on this momentum and driving positive change
          within our organization as we continue to nurture our culture of
          collaboration, empathy, and growth.”
          <br></br>
          <br></br>
          Now in its third year, the award is based on employee feedback and
          requires a satisfaction rating of at least 75 percent to qualify. This
          recognition highlights RSG International’s success in creating a
          workplace where employees feel engaged, satisfied, and valued.
          <br></br>
          <br></br>
          “This award reflects our collective commitment to fostering an
          environment where every voice is heard, every contribution is valued,
          and every team member thrives,” added Lisa Laronde, President of RSG
          International. “Congratulations to our teams in Canada and the United
          States on this outstanding achievement.”
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoNineteen}
          alt="Three women working happily."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/national-indigenous-history-month-celebrating-indigenous-cultures"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/employee-spotlight-sharon-trac-at-powell-contracting"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleNineteen;
