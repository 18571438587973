import React from "react";
import { Link } from "react-router-dom";
import "./ArticleFour.scss";
import NewsPhotoFour from "../../../assets/images/Photos/LisaNewsPhoto.jpg";

import Footer from "../../../components/Footer/Footer";

const ArticleFour = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">Oct 31, 2023</span>
        <h3 className="article-page__title">
          Lisa Laronde: WXN 2023 Canada's Most Powerful Women - RSG
          International
        </h3>
        <p className="article-page__text">
          <br></br>
          She’s known as an unapologetic, purpose-driven leader who elevates
          those around her, creates opportunities for people of diverse
          backgrounds, and cultivates a company culture of respect and
          appreciation.
          <br></br>
          <br></br>
          Lisa Laronde, president of RSG International, is being recognized as
          one of Canada’s Most Powerful Women through the Women’s Executive
          Network (WXN) Top 100 list for 2023.
          <br></br>
          <br></br>
          “I truly never expected to be in this position, and it is an absolute
          honour to be recognized, as this award validates all that I have done
          and all I have achieved,” explains Laronde.
          <br></br>
          <br></br>
          Laronde has transformed RSG International into a worldwide leader in
          the road safety sector, marking a surge in revenue and profit since
          taking over as president in 2020. Moreover, Laronde is dedicated to
          empowering women in construction, a sector which is still very much
          male-dominated.
          <br></br>
          <br></br>
          “I love what I do and the construction industry, but I cannot deny my
          belief that as a whole it needs to do more to promote women in
          leadership roles,” adds Laronde. “I will continue to do my part to
          change that narrative to empower women to reach and exceed their full
          potential.”
          <br></br>
          <br></br>
          More than 100 women have been recognized for making a transformational
          difference in their fields, while actively working to shape a more
          inclusive future especially in underrepresented areas.
          <br></br>
          <br></br>
          “It takes a powerful woman to lead like a legend in her field,
          organization, or community, and it takes a powerful woman to empower
          those around her to do the same,” said Sherri Stevens, CEO of WXN.
          “This year’s winners embody that power, giving us hope when we need it
          most, setting new bars and changing the status quo. They are legends
          celebrated not just for the things they do, but also how they touch
          the lives of others.”
          <br></br>
          <br></br>
          The Top 100 Awards span the private, public, and not-for-profit
          sectors, with the winners selected by WXN's Diversity Council of
          Canada.
          <br></br>
          <br></br>
          “What sets Lisa apart as a leader is her unwavering belief in
          inclusive leadership, one that fosters diversity of thought and
          encourages collaboration,” explains Jamie West, president of Peninsula
          Construction, an RSG International company.
          <br></br>
          <br></br>
          West sits on the board of the Canadian Association of Women in
          Construction, a group dedicated to breaking down barriers for women in
          construction, Laronde heads the board as its newly elected president.
          <br></br>
          <br></br>
          “Her [Laronde] unwavering support for the success of her team members,
          especially women in the industry, sets her apart as a beacon of
          inspiration and a true role model,” added West. Laronde says during
          her career she’s always led by example, been her unapologetic self and
          worked to be a transformational leader.
          <br></br>
          <br></br>
          “I want to be part of the solution, to blaze trails together, united
          with one voice. I don’t want women to follow in my footsteps, I want
          to see a path that’s free and clear and ultimately, I want to see new
          women leaders surpass me, bringing new and innovative ideas to the
          table.” Laronde’s accomplishments are being applauded by her team who
          believe she pushes them to never accept the status quo and always ask
          why.
          <br></br>
          <br></br>
          “Lisa's want to push the envelope and willingness not to follow what
          has been done before is refreshing. I have found that having a woman
          for a leader changes the perspective and you are usually pushed in
          ways you wouldn't expect. Lisa exudes empathy, compassion, and passion
          and wants to continually do better. There is nothing greater than
          being challenged,” explains Elliot Steele, the Vice President,
          Technology at RSG International.
          <br></br>
          <br></br>
          Since 2012, the Canada’s Most Powerful Women: Top 100 Awards has
          recognized and celebrated a total of 1,628 extraordinary women
          including The Honourable Rona Ambrose, former leader of Canada’s
          Official Opposition in the House of Commons; Dr. Makaziwe Mandela,
          global activist, head of the House of Mandela and the daughter of
          Nelson Mandela; The Honourable Dr. Jean Augustine, the first African-
          Canadian woman elected to the House of Commons; Melissa Grelo, co-host
          of CTV’s The Social; Michele Romanow, tech entrepreneur and venture
          capitalist; and Susan Aglukark, award-winning musician.
          <br></br>
          <br></br>
          Read our press release{" "}
          <a
            href="https://www.einpresswire.com/article/665455987/first-woman-president-in-a-male-dominated-industry-is-recognized-as-one-of-canada-s-top-100-most-powerful-women"
            target="_blank"
            rel="noopener noreferrer"
            className="article-page__link"
          >
            here
          </a>
          .<br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoFour}
          alt="The CEO of RSG International, Lisa Laronde, standing in front of Powell Construction equipment."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/rsg-international-strikes-a-new-strategic-partnership"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/rsg-international-looks-to-team-up-employees-with-ai-partner"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleFour;
