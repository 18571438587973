import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwentySix.scss";
import NewsPhotoTwentySix from "../../../assets/images/Photos/NewsPhotoTwentySix.png";

import Footer from "../../../components/Footer/Footer";

const ArticleTwentySix = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">September 30, 2024</span>
        <h3 className="article-page__title">
          National Day for Truth and Reconciliation at RSG International
        </h3>
        <p className="article-page__text">
          On National Day for Truth and Reconciliation, RSG International stands
          in solidarity with Indigenous communities. We reflect on the painful
          legacy of residential schools and the ongoing journey toward healing.
          <br></br>
          <br></br>
          Today, we honour the survivors, their families, and the communities
          that supported them. It’s essential to acknowledge not only the harms
          of the past but also our commitment to restoring relationships with
          Indigenous peoples. We must actively support their rights, address
          systemic inequalities, and foster cultural preservation.
          <br></br>
          <br></br>
          This day, also known as Orange Shirt Day, began as a movement to
          recognize the trauma of residential schools and celebrate the
          resilience of Indigenous peoples. The orange shirt symbolizes the
          story of Phyllis Webstad, whose new shirt was taken on her first day
          at a residential school, representing the stripping away of identity
          and dignity.
          <br></br>
          <br></br>
          At RSG International, we wear orange to express our solidarity, raise
          awareness of these injustices, and remind ourselves of our
          responsibility to build a future rooted in truth, respect, and
          inclusion.
          <br></br>
          <br></br>
          As we commemorate this day, we reflect on our role in supporting
          Indigenous communities. Together, let’s work towards a more equitable
          and compassionate Canada for all.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTwentySix}
          alt="National Day for Truth and Reconciliation Poster."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/rsg-international-celebrates-national-construction-appreciation-week"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/rsg-international-introduces-inclusive-ppe-in-partnership-with-marks-commercial"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwentySix;
