import React from "react";
import { Link } from "react-router-dom";
import "./ArticleNine.scss"; 
import NewsPhotoNine from "../../../assets/images/Photos/NewsPhotoNine.JPG"; 

import Footer from "../../../components/Footer/Footer";

const ArticleNine = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">March 04, 2024</span>
        <h3 className="article-page__title">
        Celebrating Women in Construction Week
        </h3>
        <p className="article-page__text">
          <br></br>
          RSG International is proud to celebrate Women in Construction Week, an annual event dedicated to highlighting the importance of women in the construction industry and advocating for their increased presence and leadership in the field. This week-long celebration aligns with our commitment to diversity, equity, and inclusion within the construction sector and beyond.
          <br></br>
          <br></br>
          "Women in Construction Week is not just about celebrating achievements; it's about creating conversations that lead to real change in the industry. It's about building a future where women have equal opportunities to contribute, lead, and innovate," says Lisa Laronde, President of RSG International. Lisa, a vocal advocate for gender equality in construction, emphasizes the importance of this week as a stepping stone towards greater inclusion and diversity in the industry.
          <br></br>
          <br></br>
          Women in Construction Week is an opportunity for us all to reflect on the progress made and the journey ahead. RSG International remains committed to breaking down barriers and creating an inclusive environment where everyone, regardless of gender, can thrive.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoNine}
          alt="A large group of construction workers posing in front of trucks and equipment."
        />

<div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/jason-spencer-joins-rsg-internationals-executive-team"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/president-of-rsg-international-launches-podcast-on-international-womens-day"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleNine;
