import React from "react";
import { Link } from "react-router-dom";
import "./ArticleFifteen.scss";
import NewsPhotoFifteen from "../../../assets/images/Photos/NewsPhotoFifteen.JPG";

import Footer from "../../../components/Footer/Footer";

const ArticleFifteen = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">May 14, 2024</span>
        <h3 className="article-page__title">
          RSG International's HR Team Clinches the Innovative HR Teams Award
        </h3>
        <p className="article-page__text">
          <br></br>
          RSG International congratulates the People and Culture team at 180 Ram
          Forest Road for their recognition as one of Canada's ‘Most Innovative
          HR Teams for 2024’.
          <br></br>
          <br></br>
          Each year, the HR Reporter acknowledges organizations that push the
          boundaries in the HR sector. These organizations introduce new
          technology, highlight individual achievements, implement innovative
          recognition strategies, and adopt progressive recruitment approaches.
          <br></br>
          <br></br>
          “Being chosen as a winner for Innovative HR teams is truly an
          incredible achievement! It means a lot to me because it reflects the
          dedication and uniqueness of our HR department. We’re committed to
          introducing fresh new ideas and initiatives, while continuously
          seeking to improve our practices. We all are genuinely committed to
          enhancing the employee experience, and I'm honoured to be a part of
          this exceptional team,” explains Leah Marche, People and Culture
          Specialist for Powell Contracting, a division of RSG International.
          <br></br>
          <br></br>
          Our People and Culture team embraces an open-door policy, organizing
          lively employee events to foster departmental mingling during work
          hours, thereby enhancing accessibility. Additionally, the team
          prioritizes destigmatizing mental health issues and cultivating an
          inclusive environment through training and peer-to-peer learning.
          <br></br>
          <br></br>
          “I am delighted to have won the Canada's Most Innovative HR Teams
          Award. It is a testament to the dedication, creativity, and tireless
          efforts of our HR team in revolutionizing our workplace practices,”
          adds Paul Stuart, Director of Human Resources at RSG International.
          “This recognition not only validates our hard work but also inspires
          us to push boundaries further, setting new standards for HR
          excellence. Together, we've sculpted a culture where innovation
          thrives, propelling our company toward greater success and fostering a
          truly transformative workplace.”
          <br></br>
          <br></br>
          Congratulations team!
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoFifteen}
          alt="RSG International's HR Team posing for a photo with their award."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/chantal-seguin-elected-president-of-the-simcoe-county-heavy-construction-association"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/rsg-international-lights-the-path-for-pink-titans-robotics-in-2025"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ArticleFifteen;
