import { useEffect } from 'react';

function TitleRoute({ title, children }) {
    useEffect(() => {
        document.title = title || 'RSG International';
    }, [title]);

    return children;
}

export default TitleRoute;

