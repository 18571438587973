import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwenty.scss";
import NewsPhotoTwenty from "../../../assets/images/Photos/NewsPhotoTwenty.png";

import Footer from "../../../components/Footer/Footer";

const ArticleTwenty = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">June 21, 2024</span>
        <h3 className="article-page__title">
          Employee Spotlight: Sharon Trac at Powell Contracting
        </h3>
        <p className="article-page__text">
          <br></br>
          Meet Sharon Trac, an Accounting Administrator at Powell Contracting, a
          division of RSG International. We had the opportunity to sit down with
          Sharon as she shares her experience with RSG International and get a
          deep dive into her personal life. Sharon's favorite project involves
          reconciling projects to invoice for holdback, she loves doing this
          because it means that the project has been completed. She cherishes
          the people she works with, emphasizing the supportive and
          collaborative environment at RSG International. For newer employees, a
          big tip Sharon wants to share is to always, “Ask for help if you need
          it, everyone is very kind and always willing to help!"
          <br></br>
          <br></br>
          Passionate about road safety, Sharon is confident driving because of
          Powell Contracting's commitment to making sure the roads are safe.
          Outside of work, she's an adrenaline junkie who loves heights,
          traveling, and trying new things. Sharon loves to relax with a puzzle
          after a long day and has inspiring goals on her bucket list, such as
          hiking to Machu Picchu, opening her art exhibition, and completing a
          triathlon. The simple, profound message she has for others is,
          "Passion drives success. If you love what you do, you will always
          succeed."
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTwenty}
          alt="Sharon Trac posing proudly for her photo."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/rsg-international-named-one-of-canadas-best-places-to-work"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/rsg-international-is-acquired-by-ramudden-global-bolstering-its-global-ambitions"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwenty;
