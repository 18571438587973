import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./RoadSafety.scss";

import { Link } from "react-router-dom";
import RoadSafetyImage from "../../../assets/images/Photos/RoadSafety.png";

const RoadSafety = () => {
  return (
    <main
      className="road-safety-container"
      data-aos="zoom-down"
      data-aos-duration="1000"
    >
      <div className="roadsafety">
        <div className="roadsafety__image-container">
          <img
            className="image-RoadSafety"
            src={RoadSafetyImage}
            alt="Two construction workers discussing road safety."
          />
        </div>
        <div className="roadsafety__content">
          <h2 className="roadsafety__content-header">We Are Road Safety</h2>
          <p className="roadsafety__content-text">
            We are a global leader in the road safety sector, composed of
            industry-leading road safety businesses across North America. These
            include B&K Enterprises, Dimensional Products, Northward Group,
            Pivot Safety Products, Peninsula Construction, Powell Contracting,
            Summit Rentals, and more.
            <br></br>
            <br></br>
            Together, our group of companies cover all facets of road safety
            infrastructure, including product development, distribution,
            installation, and traffic management.
          </p>
          <Link to="/about">
            <button className="roadsafety__content-button">Learn More</button>
          </Link>
        </div>
      </div>
    </main>
  );
};

export default RoadSafety;
