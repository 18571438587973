import React from "react";
import { Link } from "react-router-dom";
import "./ArticleFive.scss";
import NewsPhotoFive from "../../../assets/images/Photos/RedBitPhoto.png";

import Footer from "../../../components/Footer/Footer";

const ArticleFive = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">Nov 27, 2023</span>
        <h3 className="article-page__title">
          RSG International Looks To Team Up Employees With AI Partner
        </h3>
        <p className="article-page__text">
          <br></br>
          This new partnership with the Estonian technology company could change
          the road building industry and the way we do business at RSG
          International.
          <br></br>
          <br></br>
          “We’d like to show people that the construction industry is creative
          and innovative,” explains Elliot Steele, Vice President, Technology at
          RSG International.
          <br></br>
          <br></br>
          Working alongside software engineering company RedBit Development and
          QMind, a group of undergraduate students from Queen’s University, RSG
          International is developing the ‘AI Partner’, a tool that can be used
          to assist employees in understanding, creating, and elevating
          proposals for contracts within the industry, leading to a more
          efficient bid process which could lead to more awarded contracts.
          <br></br>
          <br></br>
          “We want to pair our staff members across our brands with a tool they
          can use to accelerate the understanding of complex documents,” adds
          Steele.
          <br></br>
          <br></br>
          As RSG International continues to grow and diversify its workforce, if
          successful, the AI Partner is expected to give employees a better
          understanding of complex documentation and help aid in training
          programs during the onboarding process in addition to policies,
          standards, and specifications outlined by the Ministry of
          Transportation (MTO).
          <br></br>
          <br></br>
          The goal of the project is not to replace humans, the AI Partner is
          intended to superpower employees in their day-to-day tasks at work.
          <br></br>
          <br></br>
          “We hope to gain knowledge of how AI can affect our businesses and how
          we can leverage it to move faster and adapt to the ever-changing
          environment. In addition, we’re looking to partner with Canadian firms
          to ensure this technology remains Canadian,” added Steele.
          <br></br>
          <br></br>
          RedBit Development and undergraduate students from Queens University
          are developing the software and hope to showcase it during the 2024
          Canadian Undergraduate Conference on Artificial Intelligence (CUCAI).
          <br></br>
          <br></br>
          “Working with RedBit has broadened my horizons as a project manager
          and given me valuable experience working with client-facing projects.
          I believe in the near future, AI will be integrated seamlessly into
          the workplace and magnify the productivity of employees in all
          industries and fields,” explains Brandon Cheung, the team lead with
          QMind.
          <br></br>
          <br></br>
          RSG International and RedBit Development believe in mentorship and say
          the team at QMind has been an integral part of the proof-of-concept
          process.
          <br></br>
          <br></br>
          The annual conference is being held in Kingston, Ontario, March 2-3.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoFive}
          alt="The CEO of RSG International, Lisa Laronde, standing in front of Powell Construction equipment."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/lisa-laronde-wxn-2023-canadas-most-powerful-women"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/mark-ayton-from-safe-roads-research-development-becomes-the-first-canadian-to-win-a-lifetime-award-from-the-transportation-research-board"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleFive;
