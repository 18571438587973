import React from "react";
import { Link } from "react-router-dom";
import "./ArticleSixteen.scss";
import NewsPhotoSixteen from "../../../assets/images/Photos/NewsPhotoSixteen.JPG";

import Footer from "../../../components/Footer/Footer";

const ArticleSixteen = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">May 31, 2024</span>
        <h3 className="article-page__title">
          RSG International Lights the Path for Pink Titans Robotics in 2025!
        </h3>
        <p className="article-page__text">
          <br></br>
          RSG International is thrilled to announce our sponsorship of the Pink
          Titans Robotics team from David & Mary Thomson Collegiate Institute in
          Toronto, Ontario for the 2025 season. This partnership highlights our
          dedication to supporting STEM education and empowering the next
          generation of innovators.RSG International is thrilled to announce our
          sponsorship of the Pink Titans Robotics team from David & Mary Thomson
          Collegiate Institute in Toronto, Ontario for the 2025 season. This
          partnership highlights our dedication to supporting STEM education and
          empowering the next generation of innovators.
          <br></br>
          <br></br>
          “This partnership will help us reach more students, especially young women, through workshops focused on robotics, gender equity in STEM, and leadership opportunities,” explained Elliot Steele, Vice President, Technology at RSG International. “We also look forward to offering mentorship opportunities where our industry professionals can share their knowledge and inspire students to consider a career in the road safety infrastructure sector.” 
          <br></br>
          <br></br>
          RSG International is at the forefront of road safety infrastructure worldwide, with a diverse portfolio of business units specializing in construction and installation, material distribution, and new product development. 
          <br></br>
          <br></br>
          The Pink Titans robotics team consists of high school students from grades 9 to 12 who have shown remarkable talent and commitment in the FIRST Robotics Competition (FRC), a renowned global STEM program. Over a challenging eight-week period, the team collaborates to design, build, and program a 50-kg robot to tackle intricate tasks. In the previous season, their robot, crafted to pick up foam rings and score them in designated areas, propelled them to their most successful year yet. The team clinched two Innovation in Control Awards and the prestigious Impact Award at three competitions, including the provincial championship. Furthermore, one of their mentors received recognition with the Volunteer of the Year Award in the FIRST LEGO League Explore program.
          <br></br>
          <br></br>
          “With financial support, the team will be able to participate in the 2025 season.  The finances are limited in a community with many external challenges affecting student success. The Pink Titans are very grateful to RSG International for helping ensure the team's continued sustainability,” added Edmund Kim, coach of the Pink Titans and a biology teacher.  “Robotics is challenging, but the experience is very rewarding when the students work alongside success and see their future in the industry.” 
          <br></br>
          <br></br>
          RSG International is deeply committed to fostering talent and opening doors for young minds to excel in STEM fields and is eager to join forces with The Pink Titans to create impactful experiences that will not only enhance student’s technical skills but also prepare them for success in higher education and the workforce.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoSixteen}
          alt="RSG International's Pink Titans Robotics Team posing for a photo with their award."
        />

        <div className="last-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/rsg-international-hr-team-clinches-the-innovative-hr-teams-award" // Update the link to the previous article
            className="nav-button"
          >
            Previous
          </Link>
          {/* Add next button if there is a following article */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleSixteen;
