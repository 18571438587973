import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./WorkersBanner.scss";

const WorkersBanner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return <main className="workers-banner-home" data-aos="zoom-in"></main>;
};

export default WorkersBanner;
