import React from "react";
import "./ContactHeader.scss";

const ContactHeader = () => {
    return (
        <div className="contactheader">
            <div className="contactheader__container">
                <h1 className="contactheader__title">Contact Us</h1>
            </div>
        </div>
    );
}

export default ContactHeader;
