import React from "react";
import "./WelcomeBanner.scss";

const WelcomeBanner = () => {
  return (
    <div className="welcomebanner" data-aos="zoom-in-down" data-aos-duration="1000">
      <div className="welcomebanner__container">
        <h1 className="welcomebanner__title">RSG International</h1>
      </div>
    </div>
  );
};

export default WelcomeBanner;
