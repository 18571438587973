import React from "react";
import "./PrivacyPolicy.scss";

import Footer from "../../components/Footer/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-policy-container">
        <div>
          <h1 className="policy-title">RSG INTERNATIONAL PRIVACY POLICY</h1>
          <p className="policy-date">Last Updated: March 28th, 2024</p>
        </div>

        <div className="policy-section">
          <p className="policy-intro">
            Welcome to RSG International ("us", "we", or "our"). Your privacy is
            important to us. This Privacy Policy outlines how we collect, use,
            disclose, and safeguard your personal information when you visit our
            website www.rsgint.com (the "Site" or “Website”).
            <br></br>
            <br></br>
            By accessing or using the Site, you agree to the terms of this
            Privacy Policy. If you do not agree with the terms of this Privacy
            Policy, please do not access the Site.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">1. Information We Collect</h2>
          <p className="policy-text">
            We collect various types of information when you visit our Site,
            including:
          </p>
          <ul className="policy-list">
            <li>
              Personal Information: We may collect personal information that you
              provide to us, such as your name, email address, phone number, and
              any other information you choose to provide.
            </li>
            <li>
              Log Data: Like many websites, we collect information that your
              browser sends whenever you visit our Site. This may include your
              computer's Internet Protocol (IP) address, browser type, browser
              version, the pages of our Site that you visit, the time and date
              of your visit, the time spent on those pages, and other
              statistics.
            </li>
            <li>
              We use certain types of Cookies on the Website. "Cookies" are
              small text files containing small amounts of information which are
              downloaded to your device when you visit a website, so that the
              website can remember some information about your browsing activity
              on the website, either for the duration of your visit or for
              repeat visits. The cookies are then sent back to the originating
              website on each subsequent visit, or to another website that
              recognizes that cookie. Cookies are useful because they allow a
              website to recognize a user's device. They do many different jobs,
              like letting you navigate between pages efficiently, remembering
              your preferences, and generally improving your user experience. In
              general, cookies make the interaction between you and websites
              faster and easier. The following describes the types of cookies
              that we currently uses or may in the future use on our Website:
            </li>
            <li>
              Session Cookies: These are temporary cookies that expire when you
              close your browser. A session cookie assigns a randomly-generated,
              unique identification number to your device when you access the
              Website. Assigning your device a number facilitates the proper
              functioning of the features of our Website by permitting us to
              maintain a persistent “state” for your session, including
              information relating to transactions you might make. We also use
              session cookies to collect anonymous information (i.e. information
              that does not identify you personally) about the ways users use
              the Website, such as which pages they visit, which links they use,
              and how long they stay on each page. We analyze this information
              (known as “click-stream information”) to better understand our
              users’ interests and needs and to improve the content and
              functionality of the Website.
            </li>
            <li>
              Persistent Cookies: Unlike a session cookie, a “persistent cookie”
              does not expire when you close your browser. It stays on your
              device until the expiration date set in the cookie (for example,
              at the end of a calendar month) or until you delete it. Persistent
              cookies can be used to “tag” your device so that the next time you
              visit (or someone using your device visits), our server will
              recognize you, not by name, but by the “tag” on your device. This
              will enable us to provide you with a personalized experience even
              if we do not know who you are. It will also allow us to collect
              more accurate information about the ways people use the Website,
              for example, how people use the Website on their first visit and
              how often they return. Using persistent cookies permits us to
              provide you with a more personalized experience and, in some
              cases, may save you the trouble of re-entering information already
              in our database.
            </li>
            <li>
              Strictly Necessary Cookies: These cookies are essential for the
              running of our Website. Without these cookies, parts of our
              Website would not function. They enable you to move around the
              Website and use its features, such as accessing secure areas.
              These cookies do not track where you have been on the Internet and
              do not gather information about you that could be used for
              marketing purposes.
            </li>
            <li>
              Performance Cookies: These cookies are used to monitor the
              performance of our Website, for example, to determine the number
              of page views and the number of unique users the Website has. Web
              analytics services may be designed and operated by third parties
              on our behalf. The information provided by these cookies allows us
              to analyze patterns of user behavior, and we use that information
              to enhance user experience or identify areas of the Website which
              may require maintenance. These cookies do not collect information
              that identifies a visitor. All information collected through these
              cookies is aggregated and therefore anonymous.
            </li>
            <li>
              Functional Cookies: These cookies allow the Website to remember
              choices you make (such as your preferences, language or the region
              you are in) and provide enhanced, more personal features. These
              cookies can also be used to remember changes you have made to text
              size, fonts and other parts of web pages that you can customize.
              The information these cookies collect may be anonymized, and they
              cannot track your browsing activity on other websites.
            </li>
            <li>
              Targeting or Advertising Cookies: These cookies, which may be
              placed on your device by us or our trusted third party service
              providers, remember that you have visited a website and use that
              information to provide you with advertising which is tailored to
              your interests. They are also used to limit the number of times
              you see an advertisement as well as help measure the effectiveness
              of the advertising campaign. They remember that you have visited a
              website and this information is shared with other organizations
              such as advertisers. Without these cookies, online advertising you
              encounter will be less relevant to you and your interests.
            </li>
            <li>
              Web Beacons: When you visit our Website, your device may receive
              one or more “web beacons” in order to assist us with delivering
              cookies, to collect anonymous information about the use of the
              Website by our users, and to deliver customized or targeted
              content to you on the Website and through our partners. Web
              beacons (also referred to as “tracking pixels,” “1x1 gifs,”
              “single-pixel gifs,” “pixel tags,” or “action tags”) are graphic
              images, usually no larger than a 1x1 pixel, placed at various
              locations on the Website. They also help us identify browser
              types, search terms that bring users to our Website, and the
              domain names of websites that refer traffic to us. We may also
              utilize web beacons to provide us with more information on any
              emails we send out. In particular, a web beacon in an email
              communication will send us information to let us know that you
              have received, opened, or acted upon an e-mail you have chosen to
              receive from us.
            </li>
            <li>
              JavaScript Tags/Pixels: These are used by us to collect anonymous
              information about the use of the Website by our users, and to
              deliver customized or targeted content to you on the Website and
              through our partners. We may use JavaScript tags to anonymously
              track how visitors interact with our Website.
            </li>
          </ul>
          <p className="policy-text">
            Some people find the idea of a website storing information on their
            device intrusive. If you would prefer to opt out of cookies, it is
            possible to manage them by changing the cookie settings on your
            device, in your browser settings. Existing cookies that have already
            been set can be deleted from your hard drive. You can also block
            some or all cookies. However, you should be aware that you might
            lose some features and functionality of our Website or Services if
            you do so. For more detailed information about cookies and how to
            manage and/or delete them, please visit http://www.aboutcookies.org
            or http://allaboutcookies.org
          </p>
          <ul className="policy-list">
            <li>
              Location Information: We may collect information about your
              location if you provide consent or if your device settings allow
              us to do so.
            </li>
            <li>
              Social Media Information: If you choose to connect with us through
              social media platforms, we may collect information from your
              social media profile in accordance with the privacy settings you
              have established on those platforms.
            </li>
            <li>
              Access Information: We may collect information about where users
              accessed our Site from, such as referral URLs or advertising
              identifiers.
            </li>
            <li>
              Device Information: We may collect information about the devices
              you use to access our Site, including the device type, operating
              system, unique device identifiers, and mobile network information.
            </li>
          </ul>
          <p className="policy-text">
            We use Google Analytics to collect information about how users
            interact with our Site. For more information on how Google Analytics
            collects and processes data, please refer to Google's Privacy
            Policy.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">2. How We Use Your Information</h2>
          <p className="policy-text">
            We may use the information we collect for various purposes,
            including:
          </p>
          <ul className="policy-list">
            <li>To provide and maintain our Site;</li>
            <li>To notify you about changes to our Site;</li>
            <li>
              To allow you to participate in interactive features of our Site
              when you choose to do so;
            </li>
            <li>To provide customer support;</li>
            <li>
              To gather analysis or valuable information so that we can improve
              our Site;
            </li>
            <li>To monitor the usage of our Site;</li>
            <li>To detect, prevent and address technical issues;</li>
            <li>
              For email marketing and remarketing purposes, including sending
              you promotional emails about our products and services or serving
              you targeted advertisements.
            </li>
          </ul>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">3. Sharing Your Information</h2>
          <p className="policy-text">
            We may share your personal information with third-party service
            providers to facilitate our Site ("Service Providers"), to provide
            the Site on our behalf, to perform Site-related services or to
            assist us in analyzing how our Site is used.
          </p>
          <ul className="policy-list">
            <li>
              We use third party service providers in order to advertise and
              market to you and manage our advertising activities. Your Personal
              Information may be shared with these third parties in order for
              them to provide such services to us.
            </li>
            <li>
              In some cases, we may disclose your Personal Information and any
              other information and/or records relating to you, if we are under
              a duty to disclose or share these details in order to comply with
              any legal obligation, or when ordered to do so by any governmental
              or administrative authority. We may also share such information if
              we believe it is necessary in order to investigate, prevent, or
              take action regarding illegal activities, suspected fraud,
              situations involving potential threats to the physical safety of
              any person, violations of our terms of use, or as otherwise
              required by law.
            </li>
          </ul>
          <p className="policy-text">
            The use, collection and disclosure of Personal Information may
            involve us transferring Personal Information across borders. Where
            we transfer Personal Information from Canada to other countries in
            which applicable laws do not offer the same level of data privacy
            protection as in your home country, we take steps to ensure that
            your data is treated securely and in accordance with this Privacy
            Notice. For example, we use approved contractual clauses, intragroup
            agreements and other measures designed to ensure that the recipients
            of your Personal Information protect it.
            <br></br>
            <br></br>
            We may use and disclose aggregated and anonymous or de-identified
            information to third parties, including the general public. For
            example, we may analyze and publish reports on usage trends or
            statistics from across our global customer base for information or
            marketing purposes, but only after we have removed any identifying
            Personal Information. This type of aggregated and anonymous or
            de-identified data can help us improve our Services and advertising,
            and it can be used by our customers and the public to better
            understand related sustainability and corporate responsibility
            trends.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">4. Data Retention</h2>
          <p className="policy-text">
            We will retain your personal information only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use your information to the extent necessary to comply
            with our legal obligations, resolve disputes, and enforce our
            policies.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">5. Your Rights</h2>
          <p className="policy-text">
            Depending on your location and applicable laws, you may have certain
            rights regarding your personal information. These rights may include
            the right to access, update, or delete your personal information. To
            exercise any of your rights listed above, whether under GDPR, CCPA,
            PPIPS, or other applicable law, please contact us at
            communications@rsgint.com.
            <br></br>
            <br></br>
            If you are an employee of one of our business customers, please
            contact your employer to exercise your rights.
            <br></br>
            <br></br>
            We intend to respond to a request for access or deletion within 1
            month of receiving a request under GDPR, within 45 days of receiving
            a request under the CPPA, or within the time-period permitted under
            any other applicable law.
            <br></br>
            <br></br>
            You have a number of rights with regard to your Personal
            Information, which are detailed below. Some of these only apply in
            specific circumstances and are qualified in several respects by
            exemptions in data protection legislation. We will advise you in our
            response to your request if we are relying on any such exemptions.
          </p>
          <ul className="policy-list">
            <li>
              Accuracy: We will make reasonable efforts to ensure that the
              Personal Information collected and used by us is accurate,
              complete and up-to-date to meet the purposes for which it is
              collected and used. We do not routinely update Personal
              Information unless it is necessary to meet the purposes for which
              it is collected. We therefore rely on you to maintain the accuracy
              of your Personal Information.
            </li>
            <li>
              Correction:  You have the right to request correction to your
              Personal Information in the event it is inaccurate, incomplete or
              out of date.
            </li>
            <li>
              Access: You have the right to access your Personal Information.
              This includes (a) identification of and access to any or all of
              your Personal Information under (We)’ custody or control; (b) an
              explanation of how that Personal Information is or has been
              collected, used or disclosed by (We); and (c) the names of any
              individuals and organizations to which your Personal Information
              has been disclosed by us.
            </li>
            <li>
              Erasure: You can request that We erases your Personal Information
              where there is no compelling reason for us to continue processing
              it.
            </li>
            <li>
              Data Portability: This right allows you to obtain the Personal
              Information that you have provided to us with your consent, or
              which was necessary for us to provide you with our Services, in a
              format which enables you to transfer that Personal Information to
              another organization. You have the right to have your Personal
              Information transferred by us directly to the other organization,
              if it is technically feasible.
            </li>
            <li>
              Restriction of Processing: You have the right in certain
              circumstances to request that We suspend processing of your
              Personal Information. Where we agree to suspend our processing of
              your Personal Information, we will still be permitted to store
              your Personal Information, but any other processing of this
              information will require your consent, subject to certain
              exemptions.
            </li>
            <li>
              Objection to Processing: You have the right to object to our use
              of your Personal Information, where it is processed on the basis
              of our legitimate interests. However, We may continue to process
              your Personal Information, despite your objection, where there are
              compelling legitimate grounds to do so or we need to process your
              Personal Information in connection with any legal claims.
            </li>
            <li>
              Withdrawal of Consent: Where We have relied upon your consent to
              process your Personal Information, you have the right to withdraw
              that consent.
            </li>
          </ul>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">6. PPIPS, GDPR, CCPA Compliance</h2>
          <p className="policy-text">
            We comply with the Protection of Personal Information in the Private
            Sector Act (PPIPS), General Data Protection Regulation (GDPR), and
            the California Consumer Privacy Act (CCPA). If you are located in
            Alberta, Québec, the European Economic Area (EEA), or California,
            you may have specific rights regarding your personal information
            under these regulations.
          </p>

          <b className="policy-text-bold">Alberta Residents</b>
          <p className="policy-text">
            If you are an Alberta resident, please be advised that your personal
            information may be:
          </p>
          <ul className="policy-list">
            <li>
              Transferred to, or accessible by, affiliates and third-party
              service providers, such as companies that provide us technical
              support, data hosting, and other services; or
            </li>
            <li>
              Stored in, and processed by, computer servers and/or other
              computer systems or equipment which are located outside of Canada,
              including the United States and elsewhere. The laws regarding the
              collection, use and disclosure of personal information, including
              PCL’s obligation to disclose personal information in certain
              circumstances, may vary based on the applicable laws of these
              jurisdictions.
            </li>
          </ul>

          <b className="policy-text-bold">Québec Residents</b>
          <p className="policy-text">
            If you are a Québec resident, the PPIPS provides you with the
            following rights:
          </p>
          <ul className="policy-list">
            <li>
              The right to request a cessation of dissemination – You have the
              right to request that PCL cease disseminating their personal
              information or that any hyperlink attached to their name and
              providing access to their personal information be de-indexed if
              the dissemination of the information violates a law or a court
              order.
            </li>
            <li>
              The right to de-indexation or re-indexation – You have to request
              de-indexation or re-indexation if certain conditions apply as set
              out in PPIPS.
            </li>
          </ul>

          <b className="policy-text-bold">European Economic Area</b>
          <p className="policy-text">
            If you are a European Union resident, the GDPR provides you with the
            following rights:
          </p>
          <ul className="policy-list">
            <li>
              The right to access – You have the right to request PCL for copies
              of your personal data. We may charge you a small fee for this
              service.
            </li>
            <li>
              The right to rectification – You have the right to request that
              PCL correct any information you believe is inaccurate. You also
              have the right to request PCL to complete the information you
              believe is incomplete.
            </li>
            <li>
              The right to erasure – You have the right to request that PCL
              erase your personal data, under certain conditions.
            </li>
            <li>
              The right to restrict processing – You have the right to request
              that PCL restrict the processing of your personal data, under
              certain conditions.
            </li>
            <li>
              The right to object to processing – You have the right to object
              to PCL processing of your personal data, under certain conditions.
            </li>
            <li>
              The right to data portability – You have the right to request that
              PCL transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </li>
            <li>
              The right to not be subject to automated decision making – You
              have the right to not be subject to automated processing where it
              produces an adverse legal effect or significantly affects the
              individual.
            </li>
          </ul>

          <b className="policy-text-bold">California Residents</b>
          <p className="policy-text">
            If you are a California resident, the CCPA provides you with the
            following rights:
          </p>
          <ul className="policy-list">
            <li>
              The right to know the specific pieces and/or categories of
              personal information that we have collected about you over the
              past 12 months, the categories of sources for that personal
              information, the business or commercial purposes for collecting
              the information, the categories of personal information that we
              have disclosed, and the categories of third parties with which the
              information was shared.
            </li>
            <li>
              The right to request that we delete the personal information we
              have collected from you (and direct our third-party service
              providers to do the same). This right is subject to several
              exceptions noted within the act.
            </li>
            <li>
              The right to opt-out of the sale of your information. PCL does not
              sell your personal information or disclose your information to
              third parties for their direct marketing purposes.
            </li>
            <li>
              If in the future we intend to sell or disclose your information
              for third-party marketing, we will provide you notice, and you
              will have the right to opt-out.
            </li>
            <li>
              The right to not be discriminated against for exercising any of
              your rights under the CCPA.
            </li>
          </ul>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">7. Cybersecurity Measures</h2>
          <p className="policy-text">
            We maintain standard cybersecurity measures to protect your personal
            information from unauthorized access, use, or disclosure. However,
            please note that no method of transmission over the Internet or
            method of electronic storage is 100% secure.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">8. Changes to This Privacy Policy</h2>
          <p className="policy-text">
            We reserve the right to make changes to this Privacy Policy at any
            time and for any reason. We will notify you of any changes by
            posting the new Privacy Policy on this page.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">9. Transfers</h2>
          <p className="policy-text">
            If you are in the European Economic Area, your personal information
            will be transferred to and processed in Canada. If you are in the
            Province of Quebec, your personal information will be transferred to
            and processed in Ontario.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">10. Children</h2>
          <p className="policy-text">
            We do not knowingly collect personal information from children under
            age 16 for any purpose. If we learn that we have collected personal
            information of a child under age 16, we will attempt to delete that
            personal information as soon as possible and take any other measures
            required under applicable law.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">11. Third-Party Links</h2>
          <p className="policy-text">
            Our Website contains links to third party sites, including the
            websites of our partners, and we recommend and encourage you to read
            the privacy policies posted on those third-party sites. We are not
            responsible for and has no control over information that is
            submitted to, or collected by, these sites.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="policy-subtitle">12. Contact Us</h2>
          <p className="policy-text">
            If you have any questions, comments, or concerns about our Privacy
            Policy, do not hesitate to contact us at:
            <br></br>
            <br></br>
            RSG International Corp.
            <br></br>
            180 Ram Forest Road
            <br></br>
            Whitchurch-Stouffville, Ontario L4A 2G8
            <br></br>
            Email: info@rsgint.com
            <br></br>
            <br></br>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our Site regarding the information that they
            shared and/or collect on the Site. This policy is not applicable to
            any information collected offline or via channels other than this
            Site.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
